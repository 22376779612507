import { InfoCircleOutlined } from '@ant-design/icons'
import { Checkbox, Spin, Tooltip } from 'antd'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { Button } from 'components/UIKit'
import { useEffect, useState } from 'react'
import {
  AccountingIcon,
  ContactsIcon,
  CRMIcon,
  CustomFormIcon,
  DashboardIcon,
  DispatchIcon,
  FastPaymentIcon,
  GPSIcon,
  InstantAppointmentIcon,
  JobsIcon,
  MapIcon,
  MembershipIcon,
  ProjectsIcon,
  PropertiesIcon,
  QuickbooksIcon,
  ReviewsIcon,
  RouteOptimizationIcon,
  ScheduleIcon,
  TimecardsIcon,
  TodoIcon,
} from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import RecurringIcon from 'containers/MainContent/Orcatec/Matrix/components/calendarV2/icons/RecurringIcon'
import { SubscriptionAPI } from 'api/Subscriptoin'
import { SubscriptionOptionalFeatures } from 'features/Settings/Subscription/types'
import { fetchUserPermissions } from 'features/Settings/UsersAndGroups/permissionSlice'
import { fetchUserByToken } from 'store/Orcatec/actions/settings/user'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

interface Props {
  onClose: () => void
}

export const Modules = ({ onClose }: Props) => {
  const dispatch = useAppDispatch()
  const authUser = useAppSelector(selectAuthUser)

  const [features, setFeatures] = useState<SubscriptionOptionalFeatures>({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getData() {
      setLoading(true)
      const data = await SubscriptionAPI.getFeatures()
      setLoading(false)
      setFeatures(data)
    }

    getData()
  }, [])

  function handleFeatureSelect(feature: keyof SubscriptionOptionalFeatures) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (_: React.MouseEvent<HTMLDivElement>) => {
      // setSelectedFeatures(prev =>
      //   prev.includes(feature)
      //     ? prev.filter(selectedFeature => selectedFeature !== feature)
      //     : [...prev, feature],
      // )
      setFeatures(prev => ({
        ...prev,
        [feature]: {
          ...prev[feature],
          status: !prev[feature].status,
        },
      }))
    }
  }

  async function handleSave() {
    setLoading(true)
    await SubscriptionAPI.updateFeaturesList(features)
    setLoading(false)

    dispatch(fetchUserPermissions(authUser?.id))
    dispatch(fetchUserByToken())

    openNotificationWithIcon('success', {
      message:
        "Congratulations! Your account setup is complete. Now you're all set to start exploring the system",
    })

    onClose?.()
  }

  const sortedFeatures = [
    'crm',
    'accounting',
    'custom_forms',
    'memberships',
    'gps',
    'time_cards',
    'quickbooks',
    'recurring_appointments',
    'map',
    'request_review',
    'todo',
  ]

  return (
    <Modal
      open
      title='Features'
      width={1200}
      closable={false}
      bodyStyle={{ height: '85vh', overflowY: 'auto' }}
      footer={
        <Footer>
          <SelectedFeaturesCounter>
            Selected{' '}
            {Object.values(features).filter(feature => !!feature.status).length}{' '}
            of {Object.keys(features).length} features
          </SelectedFeaturesCounter>

          <Button onClick={handleSave} loading={loading}>
            Select later
          </Button>
          <Button type='primary' onClick={handleSave} loading={loading}>
            Done
          </Button>
        </Footer>
      }
    >
      <Wrapper>
        <p
          style={{
            textAlign: 'center',
            fontWeight: 500,
            fontSize: 24,
          }}
        >
          Hello, {authUser.full_name || 'User'}!
        </p>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          Please, select the features, that you need for your business:
        </p>
        <p
          style={{
            textAlign: 'center',
            marginBottom: 20,
          }}
        >
          You can select features later on the{' '}
          <span style={{ fontWeight: 500 }}>
            Settings &gt; Subscription &gt; My Plan page.
          </span>
        </p>

        <Spin spinning={loading}>
          <Section>
            {/*  <Heading>
              <ListTitle>Optional Features</ListTitle>
              <Tooltip title='These features you can select for your workflow. You can select features later on the Settings > Subscription > My Plan page.'>
                <InfoCircleOutlined style={{ color: '#4285f4' }} />
              </Tooltip>
            </Heading> */}
            <List>
              {sortedFeatures.map(feature => (
                <Module
                  key={OPTIONAL_FEATURES[feature].title}
                  onClick={handleFeatureSelect(feature)}
                  active={!!features[feature]?.status ?? true}
                >
                  <Checkbox
                    checked={!!features[feature]?.status ?? true}
                    // onChange={handleFeatureSelect(feature)}
                  />

                  <ModuleInfo>
                    <Title>
                      {OPTIONAL_FEATURES[feature]?.icon}{' '}
                      {OPTIONAL_FEATURES[feature]?.title}
                    </Title>
                    <Description>
                      {OPTIONAL_FEATURES[feature].description}
                    </Description>
                  </ModuleInfo>
                </Module>
              ))}
            </List>
          </Section>
        </Spin>

        <Section>
          <Heading>
            <ListTitle>Core Features</ListTitle>
            <Tooltip title='These features are opened for you by default. You can remove the feature in the Settings>Users>Permission'>
              <InfoCircleOutlined style={{ color: '#4285f4' }} />
            </Tooltip>
          </Heading>
          <List>
            {Object.keys(DEFAULT_FEATURES).map(feature => (
              <Module key={DEFAULT_FEATURES[feature].title} active>
                {/* <Checkbox checked /> */}

                <ModuleInfo>
                  <Title>
                    {DEFAULT_FEATURES[feature]?.icon}{' '}
                    {DEFAULT_FEATURES[feature]?.title}
                  </Title>
                  <Description>
                    {DEFAULT_FEATURES[feature].description}
                  </Description>
                </ModuleInfo>
              </Module>
            ))}
          </List>
        </Section>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
`
const Section = styled.div`
  margin-bottom: 30px;
`
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`
const ListTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
`
const Module = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid;
  border-color: ${props => (props.active ? ' #206FED' : ' #e6e8ee')};
  background-color: ${props => (props.active ? '#F1F6FD' : '#fff')};
  border-radius: 8px;
  padding: 24px 24px;
  gap: 24px;
  cursor: pointer;
`
const ModuleInfo = styled.div``
const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 20px;
  }
`
const Description = styled.p`
  color: #4d4d4d;
`

const Footer = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  gap: 12px;
`

const SelectedFeaturesCounter = styled.p`
  padding: 8px 16px;
  background-color: #4688f133;
  color: #206fed;
  border-radius: 18px;
  margin-right: auto;
`

const DEFAULT_FEATURES = {
  projects: {
    title: 'Projects',
    description: 'Manage all your projects in one place effortlessly.',
    icon: <ProjectsIcon />,
  },
  dispatch: {
    title: 'Dispatch',
    description:
      'See all visits and appointments assigned to your workers. Create, view, and manage events with ease.',
    icon: <DispatchIcon />,
  },
  schedule: {
    title: 'Schedule',
    description:
      'Designed to display appointments (visits) for individual workers, making scheduling a breeze.',
    icon: <ScheduleIcon />,
  },
  properties: {
    title: 'Properties',
    description:
      'Manage your clients’ addresses efficiently in one convenient module.',
    icon: <PropertiesIcon />,
  },
  contacts: {
    title: 'Contacts',
    description:
      'Create detailed client profiles and quickly view their info, rate, appointments and projects.',
    icon: <ContactsIcon />,
  },
  job_management: {
    title: 'Job Management',
    description:
      'Access all worker visits generated from projects in one place.',
    icon: <JobsIcon />,
  },
  simple_map: {
    title: 'Map',
    description:
      'Visualize the locations of scheduled events for your workers directly on the map.',
    icon: <MapIcon />,
  },
  dashboard_management: {
    title: 'Dashboard Management',
    description:
      'View and manage key business metrics, employee performance, and project progress all in one place.',
    icon: <DashboardIcon />,
  },
  instant_appointment: {
    title: 'Instant Appointment',
    description:
      'Schedule appointments with ease, allowing clients to book and confirm visits instantly.',
    icon: <InstantAppointmentIcon />,
  },
  fast_payment: {
    title: 'Fast Payment',
    description:
      'Enable quick, secure transactions, and process payments for services in just a few clicks',
    icon: <FastPaymentIcon />,
  },
}
const OPTIONAL_FEATURES = {
  recurring_appointments: {
    title: 'Recurring Events',
    description:
      'Simplify your workflow and save time by effortlessly creating and managing all your recurring appointments and visits for various scenarios.',
    icon: <RecurringIcon />,
  },
  time_cards: {
    title: 'Time cards',
    description:
      'Allows you to keep track of time worked by your employees to boost productivity and efficiency and create accurate payroll records.',
    icon: <TimecardsIcon />,
  },
  gps: {
    title: 'GPS Tracking',
    description:
      'Allows you to track the exact past and current location of your workers on the map to manage their performance and for security purposes.',
    icon: <GPSIcon />,
  },
  map: {
    title: 'Route Optimization',
    description:
      'Efficiently manage your field appointments by viewing them on a map and finding the best routes to save time and increase productivity for your team.',
    icon: <RouteOptimizationIcon />,
  },
  request_review: {
    title: 'Request for Review',
    description:
      'Enable your customers to easily share their feedback and leave reviews for your service on social media, while safeguarding your online reputation from negative reviews.',
    icon: <ReviewsIcon />,
  },
  todo: {
    title: 'ToDo',
    description:
      'Enables you to easily create, assign, and monitor tasks within your team.',
    icon: <TodoIcon />,
  },
  quickbooks: {
    title: 'Quickbooks integration',
    description:
      'Allows you to integrate with your Quickbooks Online account and export your data from Orcatec to Quickbooks for accounting purposes.',
    icon: <QuickbooksIcon />,
  },
  accounting: {
    title: 'Accounting',
    description:
      "Helps you keep an accurate and complete record of all your business's financial activities in one place. You can easily track your profits, expenses, labor hours, and account balances, and export the data whenever necessary. Free version of this feature is limited to 10 rows for all tables",
    icon: <AccountingIcon />,
  },
  memberships: {
    title: 'Memberships',
    description:
      'Create your own loyalty program and reward your clients with discounts and special offers. You can set multiple custom membership statuses to take a personal approach in your business and build lasting brand loyalty.',
    icon: <MembershipIcon />,
  },
  custom_forms: {
    title: 'Custom Forms',
    description:
      'Create and add your own customized forms to projects and events to collect and manage information efficiently. Free version of this feature is limited to 30 forms per month and 1 template',
    icon: <CustomFormIcon />,
  },
  crm: {
    title: 'CRM',
    description:
      'Simplify your sales process with our lead management system. Manage leads effortlessly with customizable boards and easy drag-and-drop features to track progress. Easily track, assign and prioritize leads to stay organized and ensure no opportunity slips through the cracks.',
    icon: <CRMIcon />,
  },
}
