import { Button } from 'components/UIKit'
import { bookDemo } from 'features/Support/api'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useState } from 'react'
// import { useAppSelector } from 'store/Orcatec/hooks'
// import { selectAuthUser } from 'store/Orcatec/selectors/user'
import styled from 'styled-components'

export const DemoPage = () => {
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  async function submit() {
    setLoading(true)
    try {
      await bookDemo()
      openNotificationWithIcon('success', {
        message: "You're all set!",
        description:
          'Our support team will be in touch soon to arrange your personalized demo session.',
      })
      setSubmitted(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <Heading>
        <h5>Book a Demo</h5>
        <p>Want to see how our solution works in action?</p>
        <p>Click Book a Demo, and our team will reach out to you shortly!</p>
        <p>
          {' '}
          We’ll contact you to schedule a personalized walkthrough tailored to
          your business needs
        </p>
      </Heading>

      <Button
        disabled={submitted}
        onClick={submit}
        type='primary'
        loading={loading}
      >
        Book a Demo
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`
const Heading = styled.div`
  margin-bottom: 50px;
`
