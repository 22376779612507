import { EditOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Skeleton, Tooltip } from 'antd'
import { DatePicker, Select } from 'components/UIKit'
import AppointmentSources from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentSources'
import AppointmentTypes from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTypes'
import ErrorBoundary from 'containers/MainContent/Orcatec/components/ErrorBoundary/ErrorBoundary'
import {
  deleteProject,
  selectProjectSlice,
  updateExtraAssignedUsers,
  updateProjectField,
} from 'features/Project/slices/projectSlice'
import { ProjectStatusGroup } from 'features/Project/types'
import {
  ModuleName,
  ProjectPermissions,
  TypeSourcePermissions,
} from 'features/Settings/UsersAndGroups'
import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import {
  selectUserPermissionsByModule,
  selectUserPermissionsByName,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment from 'moment-timezone'
import { Link, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import styled from 'styled-components'

const industriesSelector = state =>
  state.orcatec.user?.me?.industries?.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

export const ProjectDetails = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const me = useAppSelector(selectAuthUser)
  const { project, status } = useAppSelector(selectProjectSlice)
  const industries = useAppSelector(industriesSelector)
  const techs = useAppSelector(state => state.orcatec.company.technicians)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const canEditTypeSource = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )

  const canDeleteProject = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_CAN_DELETE],
    [project.user_id, ...(project?.extraAssignedUsers || [])],
    me.id,
  )

  const canEditProjectDetails = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_SETTINGS_CAN_EDIT_RELATIONS],
    [project.user_id, ...(project?.extraAssignedUsers || [])],
    me.id,
  )

  const handleProjectDelete = async () => {
    if (!canDeleteProject) {
      return openNotificationWithIcon('warning', {
        message: 'You do not have permissions to perform this action',
      })
    }

    const res = await dispatch(deleteProject(project.id))

    if (res.meta.requestStatus === 'rejected') {
      return openNotificationWithIcon('error', {
        message: res?.payload?.response?.data?.message || 'Project not found',
      })
    }

    history.replace('/projects')
  }

  const handleInfoUpdate = e => {
    const { name, value } = e.target

    const field = { [name]: value }
    dispatch(updateProjectField(field))
  }

  const disabled = ![
    ProjectStatusGroup.Estimate,
    ProjectStatusGroup.Cold,
  ].includes(project.status)

  const handleTechUpdate = (e: Event) => {
    const { value } = e.target

    dispatch(
      updateProjectField({
        user_id: value,
        user: techs.find(tech => tech.id === value),
      }),
    )
  }

  const handleAdditionalTechUpdate = (_e: Event, value: number[]) => {
    dispatch(updateExtraAssignedUsers(value))
  }

  if (status === 'loading') return <Skeleton active />

  const techsOptions = techs?.map(tech => ({
    label: tech.full_name,
    value: tech.id,
  }))

  return (
    <ErrorBoundary>
      <Wrapper>
        <Row>
          <Title>Assigned To:</Title>

          <Select
            value={project.user_id}
            name='user_id'
            options={techsOptions}
            onChange={handleTechUpdate}
            style={{ width: 155 }}
          />

          <EditIcon />
        </Row>

        <Row>
          <Title>Additional Assignee:</Title>

          <Select
            mode='multiple'
            value={project?.extraAssignedUsers || []}
            name='extraAssignedUsers'
            options={techsOptions}
            onChange={handleAdditionalTechUpdate}
            style={{ width: 155 }}
          />

          <EditIcon />
        </Row>

        <Row>
          <Title>Created By:</Title>
          <p style={{ whiteSpace: 'break-spaces' }}>
            {project.created_by?.full_name}{' '}
            {moment
              .utc(project.created_at)
              .local()
              .format('[on] MM/DD/YYYY [at] hh:mm a')}
          </p>
        </Row>

        {project?.appointment_id && (
          <Row>
            <Title>From appointment:</Title>
            <StyledLink to={`/appointment/${project?.appointment_id}`}>
              Appointment #{project?.appointment_id}
            </StyledLink>
          </Row>
        )}

        {project?.opportunity && (
          <Row>
            <Title>From opportunity:</Title>
            <Tooltip title={project?.opportunity?.name} mouseLeaveDelay={0}>
              <OpportunityName>{project?.opportunity?.name}</OpportunityName>
            </Tooltip>
          </Row>
        )}

        {project.template_name && (
          <Row>
            <Title>From template:</Title>
            <p style={{ alignSelf: 'flex-end' }}>{project.template_name}</p>
          </Row>
        )}

        <Row disabled={!canEditProjectDetails}>
          <Title>Industry:</Title>
          <Tooltip
            mouseLeaveDelay={0}
            title={
              !canEditProjectDetails
                ? 'You do not have permissions to perform this action'
                : disabled
                ? 'Industry can only be changed when project is in Estimate status'
                : ''
            }
          >
            <Select
              value={project.industry_id}
              name='industry_id'
              options={industries}
              onChange={handleInfoUpdate}
              disabled={disabled || !canEditProjectDetails}
              showSearch={false}
              style={{ width: 155 }}
            />
          </Tooltip>
          <EditIcon disabled={disabled || !canEditProjectDetails} />
        </Row>

        <Row disabled={!canEditProjectDetails}>
          <Title>Type:</Title>
          <Tooltip
            mouseLeaveDelay={0}
            title={
              !canEditProjectDetails
                ? 'You do not have permissions to perform this action'
                : ''
            }
          >
            <AppointmentTypes
              name='type_id'
              value={project.type_id}
              onChange={handleInfoUpdate}
              isLead={false}
              required={false}
              disableCRUD={!canEditTypeSource}
              withoutLabel
              style={{ width: '168px' }}
              withoutColor
              disabled={!canEditProjectDetails}
              disableClearable
              cropped
            />
            <EditIcon disabled={!canEditProjectDetails} />
          </Tooltip>
        </Row>

        <Row disabled={!canEditProjectDetails}>
          <Title>Source:</Title>
          <Tooltip
            mouseLeaveDelay={0}
            title={
              !canEditProjectDetails
                ? 'You do not have permissions to perform this action'
                : ''
            }
          >
            <AppointmentSources
              name='source_id'
              title='Source'
              value={project.source_id}
              onChange={handleInfoUpdate}
              required={false}
              disableCRUD={!canEditTypeSource}
              withoutLabel
              style={{ width: '168px' }}
              allowClear={false}
              disabled={!canEditProjectDetails}
              disableClearable
            />
            <EditIcon disabled={!canEditProjectDetails} />
          </Tooltip>
        </Row>

        <Row disabled={!canEditProjectDetails}>
          <Title>Issue date:</Title>
          <Tooltip
            mouseLeaveDelay={0}
            title={
              !canEditProjectDetails
                ? 'You do not have permissions to perform this action'
                : ''
            }
          >
            <DatePicker
              value={project?.issue_date}
              onChange={(_, value) =>
                handleInfoUpdate({
                  target: {
                    name: 'issue_date',
                    value,
                  },
                })
              }
              allowClear={false}
              disabled={!canEditProjectDetails}
            />
          </Tooltip>
        </Row>

        {project.date_of_sale && (
          <Row disabled={!canEditProjectDetails}>
            <Title>Date of Sale:</Title>
            <Tooltip
              mouseLeaveDelay={0}
              title={
                !canEditProjectDetails
                  ? 'You do not have permissions to perform this action'
                  : ''
              }
            >
              <DatePicker
                value={project?.date_of_sale}
                onChange={(_, value) =>
                  handleInfoUpdate({
                    target: {
                      name: 'date_of_sale',
                      value,
                    },
                  })
                }
                allowClear={false}
                disabled={!canEditProjectDetails}
              />
            </Tooltip>
          </Row>
        )}

        {project.status === ProjectStatusGroup.Estimate && (
          <Popconfirm
            title='Are you sure you want to delete this Project?'
            onConfirm={handleProjectDelete}
          >
            <Button style={{ marginTop: 30 }} danger>
              Delete Project
            </Button>
          </Popconfirm>
        )}
      </Wrapper>
    </ErrorBoundary>
  )
}

const Wrapper = styled.div`
  input,
  & > span {
    font-size: 14px;
    padding: 0 !important;
    background: none !important;
  }

  .ant-select-selection-overflow {
    max-width: 140px;
  }

  .ant-select-selector,
  fieldset,
  .MuiAutocomplete-root,
  .ant-picker {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  .MuiAutocomplete-input {
    padding-right: 20px !important;
    margin-top: 2px;
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select {
    top: -3px;
    /* height: 20px; */
  }
  .orcatec-input {
    margin-left: -5px;
  }

  .ant-select-selection-item {
    /* max-width: 75%; */
  }

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`
const Row = styled.div<{ disabled?: boolean }>`
  display: flex;
  position: relative;

  .MuiAutocomplete-input {
    color: ${props => (props.disabled ? '#898989' : 'initial')};
  }

  .ant-picker-suffix {
    color: ${props => (props.disabled ? '#bfbfbf' : '#4186f4')};
  }
`
const Title = styled.p`
  color: #999999;
  flex: 0 0 40%;
`

const EditIcon = styled(EditOutlined)<{ disabled?: boolean }>`
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 15px;
  cursor: pointer;
  color: ${props => (props.disabled ? '#bfbfbf' : '#4186f4')};
  pointer-events: none;
`
const StyledLink = styled(Link)`
  cursor: pointer;
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  transition: color 0.3s;
  align-self: flex-end;
`

/* const BaldSelect = styled.div`
  .ant-select-selector {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }
` */

const OpportunityName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
