import axiosOrcatec from 'api/axiosInstance'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { IConctactUs } from 'features/Settings/Subscription/components/ContactUs/ContactUs'
import {
  IBilling,
  PaymentMethod,
  SubscriptionOptionalFeatures,
  SubscriptionPlan,
} from 'features/Settings/Subscription/types'

const getBilling = () =>
  axiosOrcatec
    .get<IBilling>('/setting/subscription/billing')
    .then(res => res.data)

const getPaymentMethods = () =>
  axiosOrcatec
    .get<PaymentMethod[]>('/setting/subscription/cards')
    .then(res => res.data)

const createPaymentMethod = (data: PaymentMethod) =>
  axiosOrcatec
    .post<PaymentMethod>(`/setting/subscription/cards`, data)
    .then(res => res.data)

const updatePaymentMethod = (data: PaymentMethod) =>
  axiosOrcatec
    .put<PaymentMethod>(`/setting/subscription/cards/${data.id}`, data)
    .then(res => res.data)

const deletePaymentMethod = (id: number) =>
  axiosOrcatec.delete(`/setting/subscription/cards/${id}`).then(res => res.data)

const getInvoices = (params: GeneralTableParams) =>
  axiosOrcatec
    .get('/setting/subscription/invoices', { params })
    .then(res => res.data)

const getFeatures = () =>
  axiosOrcatec
    .get<SubscriptionOptionalFeatures>('/setting/subscription/features')
    .then(res => res.data)

const updateFeaturesList = (data: SubscriptionOptionalFeatures) =>
  axiosOrcatec
    .put<SubscriptionOptionalFeatures>(
      `/setting/subscription/save-state-modules`,
      { modules: data },
    )
    .then(res => res.data)

const getPlanUsage = () =>
  axiosOrcatec
    .get<Record<string, { used: number; total: number }>>(
      '/setting/subscription/usage',
    )
    .then(res => res.data)

const updateModuleStatus = (data: {
  module: keyof SubscriptionOptionalFeatures
  status: boolean
}) =>
  axiosOrcatec
    .patch(`setting/subscription/switch-state-module`, data)
    .then(res => res.data)

const updatePlan = (
  planId: number,
  data: { plan: SubscriptionPlan; modules: string[]; users: number[] },
) =>
  axiosOrcatec
    .patch(`setting/subscription/${planId}`, data)
    .then(res => res.data)

const payNow = () =>
  axiosOrcatec.post(`setting/subscription/pay-now`).then(res => res.data)

const deactivateUsers = (users: number[]) =>
  axiosOrcatec
    .patch(`setting/subscription/deactivate/users`, { users })
    .then(res => res.data)

const cancelSubscription = () =>
  axiosOrcatec
    .patch(`setting/subscription/switch-subscription`, { cancel_charge: true })
    .then(res => res.data)

const contactUs = (data: IConctactUs) =>
  axiosOrcatec
    .post(`setting/subscription/contact-about-enterprise-plan`, data)
    .then(res => res.data)

export const SubscriptionAPI = {
  getBilling,
  getPaymentMethods,
  createPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  getInvoices,
  getFeatures,
  updateFeaturesList,
  getPlanUsage,
  updateModuleStatus,
  updatePlan,
  payNow,
  cancelSubscription,
  deactivateUsers,
  contactUs,
}
