import { Button, TextField } from 'components/UIKit'
import { ImageUploader } from 'components/UIKit/ImageUploader/ImageUploader'
import { getMediaURL } from 'helpers/getMediaURL'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import FileUploadService from 'services/FileUploadService'
import { Info, Row, Text } from './UserForm.style'
import { UserIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { useRef } from 'react'

export const UserForm = () => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  const uploadRef = useRef(null)
  const profile_picture = useWatch({ name: 'profile_picture' })

  const handleUploadImage = async (image: File) => {
    const [res] = await FileUploadService.upload(image)

    setValue('profile_picture', res.path)
    setValue('profile_picture_id', res.id)
  }

  const handleDeleteImage = async () => {
    setValue('profile_picture', null)
    setValue('profile_picture_id', null)
  }

  const handleButtonClick = () => {
    const syntheticEvent = {
      target: uploadRef.current?.upload?.uploader?.fileInput,
    }

    uploadRef.current?.upload?.uploader?.onClick(syntheticEvent)
  }

  return (
    <>
      <Row style={{ alignItems: 'center' }}>
        <ImageUploader
          image={getMediaURL(profile_picture)}
          onUploadFinish={handleUploadImage}
          onDeleteImage={handleDeleteImage}
          customIcon={<UserIcon />}
          ref={uploadRef}
        />

        <Info>
          <Button secondary onClick={handleButtonClick}>
            Upload your profile photo
          </Button>
          {/* <Title>Upload your profile photo</Title> */}
          <Text>We recommend a photo JPG, PNG, max size 20 Mb.</Text>
        </Info>
      </Row>

      <Controller
        name='full_name'
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label='Full name'
            required
            error={errors?.full_name?.message as string}
            placeholder='Enter the full name'
          />
        )}
      />

      <Row>
        <Controller
          name='title'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label='Job title'
              required
              error={errors?.title?.message as string}
              placeholder='Enter the job title'
            />
          )}
        />
        <Controller
          name='phone'
          control={control}
          render={({ field: { value, onChange } }) => (
            <ReactInputMask
              mask='(999) 999-9999'
              value={value}
              onChange={onChange}
            >
              {() => (
                <TextField
                  label='Phone'
                  required
                  error={errors?.phone?.message}
                  placeholder='(000) 000-0000'
                />
              )}
            </ReactInputMask>
          )}
        />
      </Row>
    </>
  )
}
