import styled from 'styled-components'

interface Props {
  onClick: () => void
  onClose?: () => void
}

export const SupportButton = ({ onClick /* onClose */ }: Props) => {
  return (
    <Button onClick={onClick}>
      Need Help?
      {/* <Popconfirm
        title='Hide "Get help" button?'
        placement='left'
        onConfirm={e => {
          e?.stopPropagation()
          onClose?.()
        }}
        onCancel={e => e?.stopPropagation()}
      >
        <CloseOutlined onClick={e => e.stopPropagation()} />
      </Popconfirm> */}
    </Button>
  )
}

const Button = styled.button`
  border-radius: 40px;
  padding: 8px 16px;
  /* background-color: #206fed; */
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.33px;
  user-select: none;
  border: none;
  background-image: linear-gradient(
    to right,
    #77a1d3 0%,
    #79cbca 51%,
    #77a1d3 100%
  );
  box-shadow: 0 0 20px #eee;
  transition: 0.5s;
  background-size: 200% auto;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
`
