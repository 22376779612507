import { createPortal } from 'react-dom'

const portalTargetElement = document.getElementById('orcatec-portals')

interface Props {
  children: React.ReactNode
}

export const Portal = ({ children }: Props) => {
  if (!portalTargetElement) return

  return createPortal(children, portalTargetElement)
}
