import { Tabs } from 'antd'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { SupportRequest } from './components/SupportRequest/SupportRequest'
import styled from 'styled-components'
import { Button } from 'components/UIKit'
import { TutorialsList } from './components/TutorialsList/TutorialsList'
import { DemoPage } from './components/DemoPage/DemoPage'

interface Props {
  openRequestTicket: boolean
  onClose: () => void
}

export const Support = ({ openRequestTicket, onClose }: Props) => {
  const items = [
    {
      label: 'Support Request',
      key: 'item-1',
      children: <SupportRequest openRequestTicket={openRequestTicket} />,
    }, // remember to pass the key prop
    { label: 'Tutorials', key: 'item-2', children: <TutorialsList /> },
    { label: 'Book a Demo', key: 'item-3', children: <DemoPage /> },
  ]

  return (
    <Modal
      title='Get help'
      open={true}
      onCancel={onClose}
      width={800}
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      footer={
        <Footer>
          <Button onClick={onClose}>Close</Button>
        </Footer>
      }
    >
      <Tabs items={items} />
    </Modal>
  )
}

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`
