import styled from 'styled-components'
import BoardColumn from './components/BoardColumn'
import { Card, Column } from './type'
import BoardCard from './components/BoardCard'
import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverEvent,
  DragEndEvent,
  pointerWithin,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { Spin } from 'antd'
import { Skeleton } from '@material-ui/lab'
import { Button } from 'components/UIKit'
import { Error } from 'types/Error'

interface Props<T> {
  isLoading: boolean
  columns: Column[]
  cards: Map<number, Card[]>
  editMode?: boolean
  error: Error | null
  onChange: (cards: Map<number, Card[]>) => void
  cardComponent: (data: T) => React.ReactNode
  onLoadMore: () => void
  onDrop: (event: T, sourceColumnId?: number) => Promise<void>
  onAddColumn?: (position?: {
    columnId: number
    place: 'before' | 'after'
  }) => void
  onColumnDelete?: (column: Column) => void
  onColumnChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    columnId: Column['id'],
  ) => void
  onAddItem?: (columnId: Column['id']) => void
  onError?: (columnId: number, property: string) => void
}

export const KanbanBoard = <T extends Card>({
  isLoading,
  columns,
  cards,
  editMode,
  error,
  onChange,
  cardComponent,
  onLoadMore,
  onDrop,
  onAddColumn,
  onColumnDelete,
  onColumnChange,
  onAddItem,
  onError,
}: Props<T>) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [activeCard, setActiveCard] = useState<Card | null>(null)
  const [previousCards, setPrevCards] = useState<Map<number, Card[]> | null>(
    null,
  )

  const isThereScroll =
    (containerRef?.current?.scrollHeight || 0) >
    (containerRef.current?.clientHeight || 0)

  const lastPostElementRef = useCallback(
    node => {
      if (isLoading) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && isThereScroll) {
          onLoadMore()
        }
      })

      if (node) observer.current.observe(node)

      return () => {
        if (observer.current) {
          observer.current.disconnect()
        }
      }
    },
    [isLoading /* , onLoadMore */],
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
    /* useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }), */
  )

  const handleDragStart = (event: DragStartEvent) => {
    if (isLoading) return

    setActiveCard(event.active.data.current?.item)

    const currentCards = new Map(cards)
    setPrevCards(currentCards)
  }

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event

      if (!over) return

      const activeId = active.id
      const overId = +over.id // cardId or columnId

      if (activeId === overId) return

      const activeData = active.data.current
      const overData = over.data.current

      if (!activeData || !overData) return
      const activeColumnId =
        activeData.type === 'item' ? activeData.item.column_id : active.id
      const overColumnId =
        overData.type === 'item'
          ? overData.item.column_id
          : +over.id.toString().replace('column-', '')

      const newCards = new Map(cards)

      if (activeData.type === 'item' && overData.type === 'item') {
        // reorder in same column
        if (activeColumnId === overColumnId) {
          const columnCards = [...(newCards.get(activeColumnId) || [])]
          const oldIndex = columnCards.findIndex(card => card.id === activeId)
          const newIndex = columnCards.findIndex(card => card.id === overId)

          if (oldIndex !== -1 && newIndex !== -1) {
            newCards.set(
              activeColumnId,
              arrayMove(columnCards, oldIndex, newIndex),
            )
          }
        } else {
          // Move card to another column
          const sourceColumnCards = [...(newCards.get(activeColumnId) || [])]
          const targetColumnCards = [...(newCards.get(overColumnId) || [])]
          const cardIndex = sourceColumnCards.findIndex(
            card => card.id === activeId,
          )

          if (cardIndex !== -1) {
            const movedCard = {
              ...sourceColumnCards[cardIndex],
              column_id: overColumnId,
            } // Set the new column ID while cloning

            // Remove the original card from sourceColumnCards
            sourceColumnCards.splice(cardIndex, 1)

            // Find the target index based on `overId`
            const overIndex = targetColumnCards.findIndex(
              card => card.id === overId,
            )

            if (overIndex !== -1) {
              // Insert the moved card at the specific position
              targetColumnCards.splice(overIndex, 0, movedCard)
            } else {
              // If `overId` is not found, add the card at the end
              targetColumnCards.push(movedCard)
            }

            newCards.set(activeColumnId, sourceColumnCards)
            newCards.set(overColumnId, targetColumnCards)
          }
        }
      } else if (overData.type === 'column' && activeData.type === 'item') {
        // move card to empty column
        const sourceColumnCards = [...(newCards.get(activeColumnId) || [])]
        const targetColumnCards = [...(newCards.get(overColumnId) || [])]

        if (activeColumnId === overId) return

        if (
          targetColumnCards?.filter(card => card.id === activeData?.item?.id)
            .length
        )
          return

        const cardIndex = sourceColumnCards.findIndex(
          card => card.id === activeId,
        )
        if (cardIndex !== -1) {
          const [movedCard] = sourceColumnCards.splice(cardIndex, 1)
          const newCard = {
            ...movedCard,
            column_id: overColumnId,
          }
          targetColumnCards.push(newCard)

          newCards.set(activeColumnId, sourceColumnCards)
          newCards.set(overColumnId, targetColumnCards)
        }
      }

      onChange(newCards)
    },
    [cards, onChange],
  )

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event
    const droppedCard: T = active.data.current?.item

    if (!over && droppedCard?.column_id === activeCard?.column_id) return

    if (
      !droppedCard ||
      !previousCards ||
      droppedCard.column_id === activeCard?.column_id ||
      !onDrop
    )
      return

    try {
      await onDrop(droppedCard, activeCard?.column_id)
    } catch (error) {
      onChange(previousCards)
    }
  }

  const handleColumnAdd = (position?: {
    columnId: number
    place: 'before' | 'after'
  }) => {
    onAddColumn?.(position)
  }

  if (!columns.length)
    return (
      <Spin spinning={isLoading}>
        <Board>
          {[...Array(5).keys()].map(column => (
            <div key={column}>
              <Skeleton animation='wave' width={300} height={70} />
              <Skeleton
                animation='wave'
                variant='rect'
                width={300}
                height={700}
              />
            </div>
          ))}
        </Board>
      </Spin>
    )

  return (
    <Wrapper ref={containerRef}>
      <Board>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          {columns.map((column, idx) => (
            <BoardColumn
              key={column.id}
              data={column}
              cardCount={cards?.get(column.id)?.length || 0}
              editMode={!!editMode}
              error={error?.[column.id]}
              isFirst={idx === 0}
              isLast={idx === columns.length - 1}
              onChange={e => onColumnChange?.(e, column.id)}
              onDelete={() => onColumnDelete?.(column)}
              onAddItem={onAddItem ? () => onAddItem(column.id) : undefined}
              onError={onError}
              onAddColumn={(position: 'before' | 'after') =>
                handleColumnAdd({ columnId: column.id, place: position })
              }
            >
              <SortableContext
                items={cards?.get(column.id)?.map(card => card.id) || []}
                strategy={verticalListSortingStrategy}
                disabled={isLoading}
              >
                {cards?.get(column.id)?.map(card => (
                  <BoardCard key={card.id} data={card} color={column.color}>
                    {cardComponent(card)}
                  </BoardCard>
                ))}
              </SortableContext>
            </BoardColumn>
          ))}

          <DragOverlay>
            {activeCard ? (
              <BoardCard data={activeCard}>
                {cardComponent(activeCard)}
              </BoardCard>
            ) : null}
          </DragOverlay>
        </DndContext>

        {editMode && onAddColumn && (
          <ColumnPlaceHolder>
            <Button
              type='primary'
              onClick={() => handleColumnAdd()}
              loading={isLoading}
            >
              Add new Stage
            </Button>
          </ColumnPlaceHolder>
        )}

        <Spin
          spinning={isLoading}
          style={{
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 100,
            zIndex: 10,
          }}
        />
      </Board>

      {!!onLoadMore && (
        <p
          ref={lastPostElementRef}
          style={{
            visibility: 'hidden',
            position: 'relative',
            bottom: 500,
          }}
        >
          End of table
        </p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: calc(100vh - 140px);
  padding: 0 16px;
  overflow: auto;
`

const Board = styled.div`
  display: inline-grid;
  grid-auto-columns: 300px;
  grid-auto-flow: column;
  gap: 8px;
  position: relative;
  min-height: 600px;
`

const ColumnPlaceHolder = styled.div`
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`
