import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import {
  getPlanUsage,
  selectSubscriptionSlice,
} from '../../slices/subscriptionSlice'
import { PlanUsage, SubscriptionPlan } from '../../types'
import { InfoPlate } from 'components/UIKit'
import styled from 'styled-components'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { useHistory } from 'react-router-dom'

type Props = {
  entity: keyof PlanUsage
}

export const PlanLimits = ({ entity = 'events' }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { planUsage, data } = useAppSelector(selectSubscriptionSlice)
  const perm = useAppSelector(
    selectUserPermissionsByModule(ModuleName.SETTINGS),
  )

  useEffect(() => {
    dispatch(getPlanUsage())
  }, [])

  const percentageUsed =
    (planUsage[entity]?.used * 100) / planUsage[entity]?.total

  if (
    data.plan !== SubscriptionPlan.Free ||
    !planUsage[entity]?.used ||
    percentageUsed < 80
  )
    return null

  const handleLinkClick = () => {
    if (!perm?.settings_can_read)
      return openNotificationWithIcon('error', {
        message: 'You have no permission',
        description:
          'Please reach out to your administrator about upgrading your plan',
      })

    history.push('/settings/subscription/my-plan')
  }

  return (
    <Wrapper>
      <InfoPlate type={percentageUsed >= 100 ? 'error' : 'warning'}>
        {getEntityMessage(entity, planUsage)}
        <Link onClick={handleLinkClick}>Upgrade your plan</Link>
      </InfoPlate>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const Link = styled.span`
  color: #4285f4;
  cursor: pointer;
`

const getEntityMessage = (entity: keyof PlanUsage, planUsage: PlanUsage) => {
  const used = planUsage[entity]?.used
  const total = planUsage[entity]?.total

  const messages = {
    users: `You have added ${used} out of ${total} free users. To add more, please `,
    links: `You have used ${used} out of ${total} free shareable event links included in your plan for this billing period. `,
    events:
      used >= total
        ? `You've used 50 free events included in your plan for this billing period. `
        : `You have ${total -
            used} out of ${total} ${entity} left before the end of this billing period. `,
    opportunities:
      used >= total
        ? `You've used 50 free ${entity} included in your plan for this billing period. `
        : `You have ${total -
            used} out of ${total} ${entity} left before the end of this billing period. `,
    projects:
      used >= total
        ? `You've used 50 free projects included in your plan for this billing period. `
        : `You have ${total -
            used} out of ${total} ${entity} left before the end of this billing period. `,
    storage: `You have ${total -
      used} out of ${total} ${entity} left before the end of this billing period. `,
  }

  return messages[entity as keyof PlanUsage]
}
