import { Search } from 'components/UIKit'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ListItem } from './components/ListItem'
import { Empty } from 'antd'
import { useLocation } from 'react-router-dom'

export const tutorials = [
  {
    title: 'Dispatch',
    slag: 'dispatch',
    children: [
      {
        title: 'How to create a new appointment in Orcatec',
        link:
          'https://scribehow.com/shared/How_to_Create_a_New_Appointment_in_Orcatec__IkQfKYqWTHaTQ5eFvurjWw',
      },
      {
        title: 'Public Links',
        link: 'https://orcatec.com/tutorial/public-links',
      },
      {
        title: 'Route Optimization',
        link: 'https://orcatec.com/tutorial/ai-route-optimization',
      },
      {
        title: 'Notification Templates',
        link: 'https://orcatec.com/tutorial/custom-notification-templates',
      },
      {
        title: 'Custom Queues',
        link: 'https://orcatec.com/tutorial/custom-queue',
      },
    ],
  },
  {
    title: 'Map',
    slag: 'map',
    children: [
      {
        title: 'Route Optimization',
        link: 'https://orcatec.com/tutorial/ai-route-optimization',
      },
    ],
  },
  {
    title: 'Schedule',
    slag: 'schedule',
    children: [
      {
        title: 'How to create a new appointment in Orcatec',
        link:
          'https://scribehow.com/shared/How_to_Create_a_New_Appointment_in_Orcatec__IkQfKYqWTHaTQ5eFvurjWw',
      },
    ],
  },
  {
    title: 'Project',
    slag: 'projects',
    children: [
      {
        title: 'How to Create an Estimate in Orcatec',
        link:
          'https://scribehow.com/shared/How_to_Create_an_Estimate_in_Orcatec__hdNFit8ZTjyLrxTHYO-o2g ',
      },
      {
        title: 'How to Create a Project in Orcatec',
        link:
          'https://scribehow.com/shared/How_to_Create_a_Project_in_Orcatec__-tq-RjWCQqqR_SQy1R0aaQ',
      },
      {
        title: 'How to Create a Custom Form in Orcatec',
        link:
          'https://scribehow.com/shared/How_to_Create_a_Custom_Form_in_Orcatec__eTOaWoDSRwOR81OcTFQ5tA',
      },
      {
        title: 'Custom forms',
        link: 'https://orcatec.com/tutorial/custom-forms',
      },
      {
        title: 'Quickbooks integration',
        link: 'https://orcatec.com/tutorial/quickbooks-integration',
      },
      {
        title: 'Price pages',
        link: 'https://orcatec.com/tutorial/price-pages',
      },
      {
        title: 'Project insights',
        link: 'https://orcatec.com/tutorial/project-insight',
      },
      {
        title: 'Adding items to the project in the app',
        link: 'https://orcatec.com/tutorial/project-items-app',
      },
      {
        title: 'Visit groups',
        link: 'https://orcatec.com/tutorial/visit-group',
      },
      {
        title: 'Add-on Items',
        link: 'https://orcatec.com/tutorial/add-on-items',
      },
      {
        title: 'Good-Better-Best',
        link: 'https://orcatec.com/tutorial/good-better-best',
      },
      {
        title: 'Optional Items',
        link: 'https://orcatec.com/tutorial/optional-items',
      },
      {
        title: 'Notification templates',
        link: 'https://orcatec.com/tutorial/custom-notification-templates',
      },
    ],
  },
  {
    title: 'Messaging',
    slag: 'messaging',
    children: [
      {
        title: 'Messaging',
        link: 'https://orcatec.com/tutorial/messaging-center',
      },
    ],
  },
  {
    title: 'Reviews',
    slag: 'reviews',
    children: [
      {
        title: 'Request for review',
        link: 'https://orcatec.com/tutorial/request-for-review',
      },
    ],
  },
  {
    title: 'Quickbooks',
    slag: 'quickbooks',
    children: [
      {
        title: 'Quickbooks integration',
        link: 'https://orcatec.com/tutorial/quickbooks-integration',
      },
    ],
  },
  {
    title: 'Forms',
    slag: 'custom-form',
    children: [
      {
        title: 'How to Create a Custom Form in Orcatec',
        link:
          'https://scribehow.com/shared/How_to_Create_a_Custom_Form_in_Orcatec__eTOaWoDSRwOR81OcTFQ5tA',
      },
      {
        title: 'Custom forms',
        link: 'https://orcatec.com/tutorial/custom-forms',
      },
    ],
  },
  {
    title: 'Settings',
    slag: 'settings',
    children: [
      {
        title: 'How to Add a New User in Orcatec',
        link:
          'https://scribehow.com/shared/How_to_Add_a_New_User_in_Orcatec__g6SN5rlrSPmFLfjW3VRGlg',
      },
      {
        title: 'How to Set up Your Company in Orcatec',
        link:
          'https://scribehow.com/shared/How_to_Set_up_Your_Company_in_Orcatec__r0IQ5610RcGwSJwB_odLLQ',
      },
      {
        title: 'Price pages',
        link: 'https://orcatec.com/tutorial/price-pages',
      },
      {
        title: 'Notification templates',
        link: 'https://orcatec.com/tutorial/custom-notification-templates',
      },
      {
        title: 'Custom queues',
        link: 'https://orcatec.com/tutorial/custom-queue',
      },
    ],
  },
]

export const Tutorials = () => {
  const location = useLocation()

  const [search, setSearch] = useState('')

  const filteredTutorials = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    return tutorials.filter(
      category =>
        category.title.toLowerCase().includes(lowerSearch) ||
        category.children.some(child =>
          child.title.toLowerCase().includes(lowerSearch),
        ),
    )
  }, [search])

  function renderList() {
    return (
      <List>
        {filteredTutorials.length ? (
          filteredTutorials.map(tutorial => (
            <ListItem
              key={tutorial.title}
              data={tutorial}
              searchValue={search}
              isOpen={location.pathname.includes(tutorial.slag) || !!search}
            />
          ))
        ) : (
          <Empty description='No search results found' />
        )}
      </List>
    )
  }

  return (
    <Wrapper>
      <SearchWrapper>
        <Search
          placeholder='Search...'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </SearchWrapper>

      {renderList()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
`
const SearchWrapper = styled.div`
  padding: 16px 16px 4px;
`
const List = styled.div`
  height: 280px;
  overflow-y: auto;
`
