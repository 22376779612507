import { yupResolver } from '@hookform/resolvers/yup'
import { Popover } from 'antd'
import { Button, Password, TextField } from 'components/UIKit'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
// import { useLoginMutation } from '../authApiSlice'
import { useState } from 'react'
import {
  CheckOutlined,
  CloseOutlined,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
// import ReCAPTCHA from 'react-google-recaptcha'
import { AuthAPI } from '../api'
import { SignUp } from '../types'
import { useAppDispatch } from 'store/Orcatec/hooks'
import { authorization } from 'store/Orcatec/actions/settings/user'
import {
  Agreement,
  Error,
  Form,
  PasswordChecklistStyled,
  SecondaryText,
  Signin,
  StyledCheckbox,
  StyledLink,
  Title,
  Wrapper,
} from './Signup.style'

const companyFormSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email address')
    .nullable()
    .required('Email is required')
    .label('Email'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(32, 'Password cannot exceed more than 32 characters')
    .matches(
      /.*\p{Ll}.*/u,
      'Password must contain at least 1 lower case letter',
    )
    .matches(
      /.*\p{Lu}.*/u,
      'Password must contain at least 1 upper case letter',
    )
    /*  .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Password must contain at least 1 special character',
    ) */
    .matches(/.*\d.*/, 'Password must contain at least 1 number'),

  password_confirmation: yup
    .string()
    .required('Confirm Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(32, 'Password cannot exceed more than 32 characters')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
  agreement: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and conditions'),
  /*  captcha: yup
    .string()
    .required('Please check the captcha checkbox')
    .nullable(), */
})

export const Signup = () => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  const methods = useForm<SignUp>({
    resolver: yupResolver(companyFormSchema),
    defaultValues: {
      email: '',
      password: '',
      password_confirmation: '',
      agreement: false,
      // captcha: null,
    },
    // mode: 'onBlur',
    // reValidateMode: 'onBlur',
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    trigger,
    // setValue,
  } = methods

  const onSubmit = async (data: SignUp) => {
    try {
      setLoading(true)

      await AuthAPI.signUp(data)

      await dispatch(authorization(data.email, data.password, true))

      // history.push('/auth/confirm-email')
      openNotificationWithIcon('success', {
        title: 'realy',
        message: 'Verification email has been sent successfully',
        description:
          "Check your inbox. If you don't find the email in your inbox, please check your spam folder.",
      })
    } catch (error) {
      const keys = error.response?.data ? Object.keys(error.response?.data) : []

      if (!keys.length) return

      keys.forEach(key =>
        setError(key as keyof SignUp, {
          type: 'custom',
          message: error.response?.data?.[key],
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  /*   const onCaptchaVerify = (token: string) => {
    setValue('captcha', token)
    trigger('captcha')
  } */

  return (
    <Wrapper>
      <Title>Sign Up</Title>

      <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label='Email'
              required
              error={errors?.email?.message}
              // autoComplete='off'
              autoComplete='new-password'
              onBlur={() => trigger('email')}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          render={({ field }) => (
            <Popover
              placement='right'
              content={
                <PasswordChecklistStyled
                  rules={[
                    'minLength',
                    'lowercase',
                    'capital',
                    'number',
                    // 'specialChar',
                    // 'match',
                  ]}
                  minLength={6}
                  value={getValues('password')}
                  valueAgain={getValues('password_confirmation')}
                  messages={{
                    minLength: 'Length 6 - 32 symbols',
                    lowercase: 'Should include at least 1 lowercase',
                    capital: 'Should include at least 1 uppercase',
                    number: 'Should include at least 1 number',
                    // specialChar: 'Special symbol',
                  }}
                  // onChange={(a, b) => console.log(a, b)}
                  iconComponents={{
                    InvalidIcon: <CloseOutlined style={{ fontSize: 24 }} />,
                    ValidIcon: <CheckOutlined style={{ fontSize: 24 }} />,
                  }}
                />
              }
              trigger='focus'
            >
              <Password
                {...field}
                label='Password'
                required
                error={errors?.password?.message}
                autoComplete='new-password'
              />
            </Popover>
          )}
        />
        <Controller
          name='password_confirmation'
          control={control}
          render={({ field }) => (
            <Password
              {...field}
              label='Confirm password'
              required
              error={errors?.password_confirmation?.message}
              autoComplete='off'
            />
          )}
        />

        <Agreement>
          <Controller
            name='agreement'
            control={control}
            render={({ field }) => (
              <StyledCheckbox {...field} error={!!errors?.agreement?.message}>
                I agree with{' '}
                <Link
                  to={{
                    pathname: 'https://orcatec.com/privacy_policy.pdf',
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link
                  to={{
                    pathname: 'https://orcatec.com/terms_and_conditions.pdf',
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of use
                </Link>
              </StyledCheckbox>
            )}
          />
          {!!errors?.agreement?.message && (
            <Error>{errors?.agreement?.message}</Error>
          )}
        </Agreement>

        {/*  <Captcha>
          <ReCAPTCHA
            sitekey='6Le_g-8pAAAAAAmbpDXDEZVnUUWAicLj3SsYLDjP'
            onChange={onCaptchaVerify}
          />
          {!!errors?.captcha?.message && (
            <Error>{errors?.captcha?.message}</Error>
          )}
        </Captcha> */}

        <Button
          block
          type='primary'
          size='large'
          loading={loading}
          // disabled={!captсha}
          htmlType='submit'
        >
          Sign Up
        </Button>
      </Form>

      <Signin>
        <SecondaryText>Already have an account?</SecondaryText>
        <StyledLink to='/auth/signin'>Sign In</StyledLink>
      </Signin>
    </Wrapper>
  )
}
