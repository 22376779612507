import { useEffect } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'

type Props = {
  callback: (data: { queType: number }) => void
}

export const useListenChangeMatrixStructure = (callback: Props) => {
  const ownerId = useAppSelector(state => state.orcatec.company.owner_id)

  useEffect(() => {
    window.Echo?.private('matrix-structure.' + ownerId).listen(
      'MatrixChangesEvent',
      callback,
    )
    return () => window.Echo.leave('matrix-structure.' + ownerId)
  }, [callback])
}

export const useListenChangeQueue = (callback: Props) => {
  const ownerId = useAppSelector(state => state.orcatec.company.owner_id)

  useEffect(() => {
    window.Echo?.private('matrix-structure.' + ownerId).listen(
      'Matrix\\QueueTypeEvent',
      callback,
    )
    return () => window.Echo.leave('matrix-structure.' + ownerId)
  }, [callback])
}

export const useListenGroupEvents = callback => {
  const ownerId = useAppSelector(state => state.orcatec.company.owner_id)

  useEffect(() => {
    window.Echo?.private('matrix-structure.' + ownerId).listen(
      'Matrix\\EventCreatedNotifier',
      callback,
    )
    return () => window.Echo.leave('matrix-structure.' + ownerId)
  }, [callback])
}

export const useListenProjectSigned = (
  callback: (event: {
    data: {
      proposalId: number
    }
  }) => void,
) => {
  const ownerId = useAppSelector(state => state.orcatec.company.owner_id)

  useEffect(() => {
    window.Echo?.private('proposal-channel.' + ownerId).listen(
      'SignedProposalEvent',
      callback,
    )
    return () => window.Echo.leave('proposal-channel.' + ownerId)
  }, [callback])
}

export const useListenTestDataDeleted = callback => {
  const { id: companyId } = useAppSelector(selectCompany)

  useEffect(() => {
    window.Echo?.private('company-message.' + companyId).listen(
      '.demo-data-were-deleted',
      callback,
    )
    return () => window.Echo.leave('matrix-structure.' + companyId)
  }, [callback])
}
