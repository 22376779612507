import { useEffect, useState } from 'react'
import { Tutorial } from '../types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { DownOutlined, ExportOutlined, RightOutlined } from '@ant-design/icons'

interface Props {
  data: Tutorial
  searchValue: string
  isOpen: boolean
}

export const ListItem = ({ data, isOpen, searchValue }: Props) => {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  function toggleOpen() {
    setOpen(!open)
  }

  return (
    <Wrapper>
      <Title onClick={toggleOpen}>
        {data.title}
        <ToggleIcon>{open ? <DownOutlined /> : <RightOutlined />}</ToggleIcon>
      </Title>

      {open && (
        <Content>
          {data.children
            .filter(item =>
              item.title.toLowerCase().includes(searchValue.toLowerCase()),
            )
            .map(item => (
              <StyledLink
                key={item.title}
                to={{ pathname: item.link }}
                target='_blank'
              >
                <LinkText>{item.title}</LinkText>
                {'  '}
                <ExportOutlined
                  style={{ fontSize: 12, position: 'relative', top: 3 }}
                />
              </StyledLink>
            ))}
        </Content>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Title = styled.p`
  padding: 8px 23px 8px 20px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f1f2fd;
    color: #206fed;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
`
const Content = styled.div`
  background-color: #fff;
  padding: 0 20px 0 30px;
`
const ToggleIcon = styled.span`
  color: #4688f1;
  font-size: 12px;

  span {
  }
`

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 12px;
`

const LinkText = styled.span`
  text-decoration: underline;
`
