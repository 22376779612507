import styled from 'styled-components'
import SupportButton from './components/SupportButton'
import { useState } from 'react'
import SupportWindow from './components/SupportWindow'
// import { useAppSelector } from 'store/Orcatec/hooks'
// import { selectAuthUser } from 'store/Orcatec/selectors/user'

export const GetHelp = ({ onOpenSupportTicket }) => {
  // const authUser = useAppSelector(selectAuthUser)

  const [supportWindow, showSupportWindow] = useState(false)

  function toggleSupportWindow() {
    showSupportWindow(!supportWindow)
  }

  return (
    <Wrapper>
      {supportWindow ? (
        <SupportWindow
          // userName={authUser.full_name}
          onClose={toggleSupportWindow}
          onOpenSupportTicket={onOpenSupportTicket}
        />
      ) : (
        <SupportButton onClick={toggleSupportWindow} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
