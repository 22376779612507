import axiosOrcatec from 'api/axiosInstance'

export async function bookDemo() {
  const res = await axiosOrcatec.post('/companies/book-demo')

  return res.data
}

export async function deleteTestData() {
  const res = await axiosOrcatec.delete('/companies/demo-data')

  return res.data
}
