// import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { tutorials } from '../GetHelp/components/Tutorials/Tutorials'
import { ListItem } from '../GetHelp/components/Tutorials/components/ListItem'
import { useMemo, useState } from 'react'
import { Search } from 'components/UIKit'
import { Empty } from 'antd'

/* const list = [
  {
    title: 'How to Create a New Appointment in Orcatec',
    link:
      'https://scribehow.com/shared/How_to_Create_a_New_Appointment_in_Orcatec__IkQfKYqWTHaTQ5eFvurjWw',
  },
  {
    title: 'How to Create an Estimate in Orcatec',
    link:
      'https://scribehow.com/shared/How_to_Create_an_Estimate_in_Orcatec__hdNFit8ZTjyLrxTHYO-o2g ',
  },
  {
    title: 'How to Add a New User in Orcatec',
    link:
      'https://scribehow.com/shared/How_to_Add_a_New_User_in_Orcatec__g6SN5rlrSPmFLfjW3VRGlg',
  },
  {
    title: 'How to Set up Your Company in Orcatec',
    link:
      'https://scribehow.com/shared/How_to_Set_up_Your_Company_in_Orcatec__r0IQ5610RcGwSJwB_odLLQ',
  },
  {
    title: 'How to Create a Custom Form in Orcatec',
    link:
      'https://scribehow.com/shared/How_to_Create_a_Custom_Form_in_Orcatec__eTOaWoDSRwOR81OcTFQ5tA',
  },
  {
    title: 'How to Create a Project in Orcatec',
    link:
      'https://scribehow.com/shared/How_to_Create_a_Project_in_Orcatec__-tq-RjWCQqqR_SQy1R0aaQ',
  },

  { title: 'Messaging', link: 'https://orcatec.com/tutorial/messaging-center' },
  { title: 'Custom forms', link: 'https://orcatec.com/tutorial/custom-forms' },
  {
    title: 'Request for review',
    link: 'https://orcatec.com/tutorial/request-for-review',
  },
  { title: 'Public links', link: 'https://orcatec.com/tutorial/public-links' },
  {
    title: 'Quickbooks integration',
    link: 'https://orcatec.com/tutorial/quickbooks-integration',
  },
  {
    title: 'Route optimization',
    link: 'https://orcatec.com/tutorial/ai-route-optimization',
  },
  { title: 'To Do', link: 'https://orcatec.com/tutorial/to-do-task' },

  { title: 'Price pages', link: 'https://orcatec.com/tutorial/price-pages' },
  {
    title: 'Project insights',
    link: 'https://orcatec.com/tutorial/project-insight',
  },
  {
    title: 'Adding items to the project in the app',
    link: 'https://orcatec.com/tutorial/project-items-app',
  },
  { title: 'Visit groups', link: 'https://orcatec.com/tutorial/visit-group' },
  { title: 'Add-on Items', link: 'https://orcatec.com/tutorial/add-on-items' },
  {
    title: 'Good-Better-Best',
    link: 'https://orcatec.com/tutorial/good-better-best',
  },
  {
    title: 'Optional Items',
    link: 'https://orcatec.com/tutorial/optional-items',
  },
  {
    title: 'Notification templates',
    link: 'https://orcatec.com/tutorial/custom-notification-templates',
  },
  { title: 'Custom queues', link: 'https://orcatec.com/tutorial/custom-queue' },
] */

export const TutorialsList = () => {
  const [search, setSearch] = useState('')

  const filteredTutorials = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    return tutorials.filter(
      category =>
        category.title.toLowerCase().includes(lowerSearch) ||
        category.children.some(child =>
          child.title.toLowerCase().includes(lowerSearch),
        ),
    )
  }, [search])

  function renderList() {
    return (
      <List>
        {filteredTutorials.length ? (
          filteredTutorials.map(tutorial => (
            <ListItem
              key={tutorial.title}
              data={tutorial}
              searchValue={search}
            />
          ))
        ) : (
          <Empty description='No search results found' />
        )}
      </List>
    )
  }

  return (
    <Wrapper>
      <Heading>
        <h5>Tutorials</h5>
        <p>Looking for guidance?</p>
        <p>Find detailed instructions and tutorials here.</p>
      </Heading>

      <Content>
        <Search
          placeholder='Search...'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />

        {renderList()}
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const Heading = styled.div`
  text-align: center;
  margin-bottom: 30px;
`

const Content = styled.div`
  width: 600px;
  margin: 0 auto;
`

const List = styled.div`
  margin-top: 20px;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`
/* const Item = styled.div``
const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
`
const StyledLink = styled(Link)`
  padding-left: 15px;
  display: block;
  text-decoration: underline;
  margin-bottom: 8px;
` */
