import {
  priceToView,
  thousandSeparator,
} from '../../../../../../../helpers/thousandSeparator'
import moment from 'moment-timezone'
import { BlackRadio } from '../../../../components/ItemsTable/BlackRadio'
// import { getDiffPrices } from 'api/Proposal'
import { Button, Checkbox } from 'antd'

import './style.scss'
import { Attachments } from 'features/Items/components/Item/components/Attachments/Attachments'
import { useState } from 'react'
import { ProjectItemProgress } from 'features/Project/Items/components/ProjectItemProgress/ProjectItemProgress'
import TableRow from 'features/Project/Items/components/ProjectItemsTable/components/TableRow'
import { useDispatch } from 'react-redux'
import {
  addProjectItem,
  itemUpdated,
  resetProjectItemsErrors,
} from 'features/Project/slices/projectItemsSlice'
import {
  selectProjectItemsBySection,
  selectProjectItemsSlice,
} from 'features/Project/projectSelectors'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Item } from 'features/Items/components/Item/Item'
import { ProjectItem, ProjectStatusGroup } from 'features/Project/types'
import { ProgressInfo } from 'features/Project/Items/components/ProjectItemProgress/components/ProgressInfo'
// import { selectCompanyTimezone } from 'store/Orcatec/selectors/company'

const initialItem = {
  attachments: [],
  barcode: '',
  category_id: 0,
  checked_option: false,
  description: '',
  global_item_id: null,
  gross_margin: null,
  group_id: 0,
  id: 0,
  is_hidden: false,
  is_material: false,
  name: '',
  net_price: 0,
  option_group_id: null,
  order_option: 0,
  position: 0,
  qty: 1,
  required_to_pay: true,
  retail_price: 0,
  section_id: 0,
  tab_id: 0,
  taxable: false,
  unit_id: null,
  unit: '',
  use_calculation: true,
}

/* const getPriceWithSign = price => {
  if (typeof price === 'undefined') return ''
  return price > 0
    ? `-${priceToView(Math.abs(price))}`
    : `+${priceToView(Math.abs(price))}`
} */

const DnDItem = ({
  item,
  items,
  ParentIdx,
  optionIndex,
  // parentID,
  // idCheckedOption,
  // propsDiffPrices,
  hideDescription,
  showDescriptionOnly,
  showPrice,
  hideZeroPrices,
  onSelectItem,
  showProgress,
  onOptionChange,
  type,
  companyTimezone,
  ...props
}) => {
  // const companyTimezone = useAppSelector(selectCompanyTimezone)

  const [showProgressForm, setShowProgressForm] = useState(false)

  const dispatch = useDispatch()

  /* const getDiffs = async (idParamsChosenOption = idCheckedOption) => {
    const requestType =
      item.itemType === 'discounts'
        ? 'Discount'
        : item.itemType === 'materials'
        ? 'Material'
        : item.itemType === 'rebates'
        ? 'Rebate'
        : item.itemType === 'items'
        ? 'items'
        : props.requestType

    if (idParamsChosenOption) {
      try {
        const params = `type=${requestType}&id=${parentID}&idForChoosenOption=${idParamsChosenOption}`
        const prices = await getDiffPrices(params)
        if (typeof props.onChangeTotalPrices === 'function') {
          props.onChangeTotalPrices(prices[1])
        }
        if (typeof props.onChangeTotalPrices === 'function') {
          props.onChangeChoosenOption(
            item.sectionIndex,
            item.itemType,
            item.itemIndex,
            optionIndex,
            prices[0],
            parentID,
            prices[1],
          )
        }
      } catch (e) {
        console.error(e, 'error')
      }
    }
  } */

  const handleItemUpdate = (updatedItem: ProjectItem) => {
    dispatch(itemUpdated(updatedItem))
  }

  const expiration = item.expiration_date || item.expiration
  let isExpired = false
  let expireStr = null

  const endOfExpirationDate = props.signatureDate
    ? moment(props.signatureDate).tz(companyTimezone)
    : moment().endOf('day')

  if (expiration === expireStr || expiration === '12/12/9999') {
    expireStr = null
    isExpired = false
  } else {
    isExpired = moment(expiration)
      .endOf('day')
      .tz(companyTimezone)
      .isBefore(endOfExpirationDate, 'days')

    expireStr =
      (!isExpired ? 'Expires on ' : 'Expired on ') +
      moment(expiration).format('MM/DD/YYYY')
  }

  //   const endOfExpirationDate =
  //     props.status !== 1 && props.status !== 5
  //       ? moment(props.signatureDate).endOf('day')
  //       : moment().endOf('day')
  //
  //   if (expiration === expireStr || expiration === '12/12/9999') {
  //     expireStr = 'no expiration'
  //     isExpired = false
  //   } else {
  //     isExpired = moment(expiration).isBefore(endOfExpirationDate, 'days')
  //     expireStr =
  //       (!isExpired ? 'Expires on ' : 'Expired on ') +
  //       moment(expiration).format('MM/DD/YYYY')
  //   }

  let files = item.attachments || item.files

  files = files?.filter(file => !!file)

  return (
    <div
      className={`dnd-item-parent dnd-item${
        item.isOption ? ' item-option' : ''
      }${
        item.isOption && items[ParentIdx].children[optionIndex].checked
          ? ' active'
          : ''
      }${isExpired ? ' expired' : ''}${props.loading ? ' loading' : ''}`}
    >
      {item.isOption && (
        <BlackRadio
          checked={items[ParentIdx].children[optionIndex].checked}
          // onChange={() => getDiffs(item.idForChoosenOption || item.id)}
          onChange={() => onOptionChange?.(item, type)}
          className='option-radio'
        />
      )}

      {/*   { (
        <div style={{ position: 'absolute', top: 15, left: -25 }}>
          <QqOutlined
            style={{ fontSize: 18 }}
            onClick={() => setShowProgress(true)}
          />
        </div>
      )} */}

      {!item.isOption && !item.required_to_pay && props.uncheckable && (
        <Checkbox
          style={{ position: 'absolute', left: -25, top: 3 }}
          checked={item.use_calculation}
          onChange={e => {
            onSelectItem(
              e.target.checked,
              // props.section,
              // item.material_type || props.itemType,
              item.id,
            )
          }}
          disabled={props.status !== 1}
        />
      )}

      <div className='description'>
        <>
          {props.isEquipment && !showDescriptionOnly && (
            <p style={{ fontWeight: 500, whiteSpace: 'pre-line' }}>
              {item.name}
            </p>
          )}

          {(props.isScopeOfWork || props.isInstants || props.isRebates) && (
            <p style={{ fontWeight: 500, whiteSpace: 'pre-line' }}>
              {item.name}
            </p>
          )}
          {!hideDescription &&
            (props.showExpiration ||
              props.isWarranty ||
              props.isScopeOfWork ||
              props.isEquipment) && (
              <span
                name='description'
                type='text'
                placeholder='Description'
                value={item.description}
                style={{ whiteSpace: 'pre-line' }}
              >
                {item.description}
              </span>
            )}
        </>
      </div>

      {!props.showTerm && (
        <div className='date'>
          {props.showExpiration && (
            <span style={{ fontStyle: 'italic' }}>
              {expireStr || 'No expiration'}
            </span>
          )}
        </div>
      )}
      {!props.showExpiration && (
        <div className='term'>
          {props.showTerm && (
            <span name='term' type='text' placeholder='Term' value={item.term}>
              {item.term}
            </span>
          )}
        </div>
      )}

      {showProgress && (
        <div style={{ flex: '0 1 100%', margin: '10px 0' }}>
          <ProgressInfo
            onClick={() => setShowProgressForm(true)}
            progress={{
              percent: item.progress,
              installed: item.installed,
              estimated: item.qty,
            }}
            unit={item.unit || null}
            // disabled={props.status !== ProjectStatus.Contract}
          />
        </div>
      )}

      <div className='actions'>
        <Attachments imageSize={35} data={files} />
      </div>

      <div className='price-section'>
        <div className='diffPrice'>
          {/* {item.isOption &&
            props.itemType !== 'instants' &&
            props.itemType !== 'rebates' &&
            (!item.checked
              ? `${getPriceWithSign(
                  propsDiffPrices?.[parentID]?.[optionIndex]?.price,
                )}`
              : '')} */}
          {item.isOption &&
            !item.checked &&
            `${item.difference > 0 ? '+' : ''}${priceToView(item.difference)}`}
        </div>
        <div className='price-per-unit'>
          {!!(item.price || !!item.retail_price) && item?.qty > 1 && showPrice && (
            <span>
              {['Instant', 'Rebate'].includes(props.requestType) ? '-' : ''}
              {priceToView(
                item.price_with_margin ?? item.price ?? item.retail_price,
                true,
                3,
              )}{' '}
              ea.
            </span>
          )}
        </div>

        <div className='qty'>
          {props.showQTY && (
            <span>{`${item.unit || 'Qty'} ${thousandSeparator(
              item.qty,
            )}`}</span>
          )}
        </div>

        <div
          className={`price ${
            !((item.price || item.retail_price) * item.qty) && hideZeroPrices
              ? 'hide'
              : ''
          } ${!['Instant', 'Rebate'].includes(props.requestType) &&
            !item.use_calculation &&
            'disabled'}`}
        >
          {showPrice && (
            <span name='price' type='text'>
              {['Instant', 'Rebate'].includes(props.requestType) ? '-' : ''}
              {priceToView(
                (props.instantsData?.[props?.id]?.instant_price ??
                  item.instant_price ??
                  item.price_with_margin ??
                  item.retail_price ??
                  item.price ??
                  item.amount) * (item.qty ?? 1),
              )}
            </span>
          )}
        </div>
      </div>

      {showProgressForm && (
        <ProjectItemProgress
          item={item}
          onClose={updatedItem => {
            setShowProgressForm(false)

            handleItemUpdate(updatedItem)
          }}
          disabled={props.status !== ProjectStatusGroup.Contract}
        />
      )}
    </div>
  )
}

const DnDItemsContainer = ({ items, ...props }) => {
  const [editingItem, setEditingItem] = useState(null)
  const [newRow, setNewRow] = useState(false)
  const [itemModal, setItemModal] = useState(false)

  const { error, status: itemsStatus } = useAppSelector(selectProjectItemsSlice)

  const dispatch = useDispatch()

  const handleItemAdd = () => {
    setEditingItem(initialItem)

    // setNewRow(!newRow)
    toggleItemsModal()
  }

  const handleChangeItem = e => {
    if (!editingItem) return

    const { name, value } = e.target

    if (
      [
        'name',
        'description',
        'net_price',
        'retail_price',
        'gross_margin',
        'is_material',
        'taxable',
      ].includes(name)
    ) {
      if (editingItem.gross_margin !== null) {
        if (name === 'net_price') {
          const calculatedRetailPrice =
            value + round((value * editingItem?.gross_margin || 0) / 100)

          setEditingItem(prevItem => ({
            ...prevItem,
            retail_price: calculatedRetailPrice,
          }))
        }
        if (name === 'gross_margin') {
          const calculatedRetailPrice =
            editingItem?.net_price +
            round((editingItem?.net_price * value || 0) / 100)

          setEditingItem(prevItem => ({
            ...prevItem,
            retail_price: calculatedRetailPrice,
          }))
        }
      }

      setEditingItem(prev => ({
        ...prev,
        global_item_id: null,
      }))
    }

    if (name === 'required_to_pay' && value) {
      setEditingItem(prev => ({
        ...prev,
        use_calculation: true,
      }))
    }

    if (error?.[name]) {
      dispatch(resetProjectItemsErrors([name]))
    }

    setEditingItem(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSaveItem = async item => {
    if (item.id) return

    const res = await dispatch(
      addProjectItem({
        ...item,
        section_id: props.sectionId,
        qty: item.qty || 1,
        group_id: props.groupId,
        position: items?.length || 0,
        approved: false,
      }),
    )

    if (res.meta.requestStatus === 'rejected') return

    if (itemModal) setItemModal(false)

    if (error) {
      dispatch(resetProjectItemsErrors())
    }

    setNewRow(false)
    setEditingItem(null)
    // setItemModal(false)
  }

  const toggleItemsModal = () => {
    setItemModal(!itemModal)
  }

  const handleSearchItem = async () => {
    toggleItemsModal()
  }

  const allItems = []
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mappedItems = [...items]
    ?.sort((a, b) => a.position - b.position)
    ?.map((item, itemIdx) => {
      if (item.options && item.options.data?.length > 1 && props.status == 1) {
        // if item has more than 1 option and proposal status is "Estimate"
        item.options.data.map((optionItem, optIdx) => {
          const checked =
            typeof optionItem.checked_option === 'boolean'
              ? optionItem.checked_option
              : optionItem.idForChoosenOption === item.options.checked_option

          const option = {
            ...optionItem,
            isOption: true,
            checked,
            itemType: item.material_type || props.itemType,
            sectionIndex: props.sectionIndex,
            itemIndex: itemIdx,
            optionIndex: optIdx,
            choosenItemPrice: item.price,
            choosenItemQty: item.qty,
            // requestType: props.requestType,
          }
          if (!allItems[itemIdx]) {
            allItems[itemIdx] = {
              checked_option: item.options.checked_option,
              parentID: item.id,
              children: [],
            }
          }
          allItems[itemIdx].children.push(option)
        })
      } else {
        allItems.push(item)
      }
    })

  return (
    <div className='dnd-items-container'>
      {Array.isArray(items) &&
        allItems.map((item, ParentIdx) => {
          if (item.children) {
            return (
              <div
                key={item.id}
                className={`dnd-item-parent dnd-items-options__wrap ${
                  props.isOptionLoading
                    ? 'dnd-items-options__wrap--loading'
                    : ''
                }`}
              >
                {item.children.map((option, optionIndex) => {
                  return (
                    <DnDItem
                      key={option.idForChoosenOption || option.id}
                      id={option.idForChoosenOption || option.id}
                      parentID={item.parentID}
                      ParentIdx={ParentIdx}
                      optionIndex={optionIndex}
                      propsDiffPrices={props.diffPrices}
                      idCheckedOption={item.checked_option}
                      items={allItems}
                      item={option}
                      showPrice={props.showPrice}
                      {...props}
                    />
                  )
                })}
              </div>
            )
          } else {
            return (
              <DnDItem
                key={item.idForChoosenOption || item.id}
                id={item.idForChoosenOption || item.id}
                ParentIdx={ParentIdx}
                item={item}
                {...props}
              />
            )
          }
        })}

      {newRow && (
        <TableRow
          error={error}
          item={editingItem}
          editingItem={editingItem}
          // onEditItem={handleEditItem}
          onChangeItem={handleChangeItem}
          onSaveItem={handleSaveItem}
          // onDeleteItem={handleDeleteItem}
          onCancel={() => {
            setNewRow(false)

            setEditingItem(null)
          }}
          onSearchItem={handleSearchItem}
          onCheckGrossMargin={() => {
            setEditingItem(prev => ({
              ...prev,
              gross_margin: editingItem?.gross_margin !== null ? null : 0,
            }))
          }}
          loading={false}
        />
      )}

      {props.status === ProjectStatusGroup.Contract &&
        props.isJobView &&
        !props.isWorkOrder && (
          <Button
            style={{ marginTop: 20, marginBottom: 10 }}
            onClick={() => handleItemAdd()}
            disabled={newRow}
          >
            + Add Item
          </Button>
        )}

      {itemModal && (
        <Item
          onClose={toggleItemsModal}
          onSave={handleSaveItem}
          // onDelete={handleDeleteItem}
          title='Add Item'
          status={itemsStatus}
          error={error}
          limited
        />
      )}
    </div>
  )
}

const ItemsTable = props => {
  const isInstants = props.title === 'Instants'
  const isRebates = props.title === 'Rebates'
  const isWarranty = props.title === 'Warranty'
  const isScopeOfWork = props.type === 'scope_of_works'
  const isEquipment = props.type === 'items'

  const showExpiration = isInstants || isRebates
  const showQTY =
    (isWarranty || isScopeOfWork || isEquipment) && !props.isHideQTY
  const showTerm = isWarranty

  const itemsList = useAppSelector(
    selectProjectItemsBySection(props.sectionId, props.groupId),
  )

  const items =
    props.items ||
    itemsList.filter(item =>
      item.option_group_id ? item.checked_option : item,
    )

  return items.length ? (
    <div className='dataTables_wrapper dt-bootstrap4'>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col'>
              <div className='dnd-items-header'>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {props.title === 'Instants' ? 'Discounts' : props.title}
                </div>
              </div>
              <DnDItemsContainer
                items={items}
                title={props.title}
                diffPrices={props.diffPrices}
                showExpiration={showExpiration}
                showTerm={showTerm}
                showQTY={showQTY}
                // isHideQTY={props.isHideQTY}
                isInstants={isInstants}
                isRebates={isRebates}
                isWarranty={isWarranty}
                isScopeOfWork={isScopeOfWork}
                isEquipment={isEquipment}
                onChangeChoosenOption={props.onChangeChoosenOption}
                sectionIndex={props.section}
                itemType={props.type}
                requestType={props.requestType}
                status={props.status}
                onChangeTotalPrices={props.onChangeTotalPrices}
                signatureDate={props.signatureDate}
                isOptionLoading={props.loading}
                showPrice={props.showPrice}
                companyTimezone={props.companyTimezone}
                {...props}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default ItemsTable
