import {
  ProjectsIcon,
  ScheduleIcon,
  AccountingIcon,
  MapIcon,
  PropertiesIcon,
  TimecardsIcon,
  DashboardIcon,
  DispatchIcon,
  ContactsIcon,
  FastPaymentIcon,
  InstantAppointmentIcon,
  JobsIcon,
  QuickbooksIcon,
  TodoIcon,
  ReviewsIcon,
  SettingsIcon,
  CustomFormIcon,
  MessagingIcon,
  CallIcon,
  CRMIcon,
  MembershipIcon,
} from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import { NotesIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Module, ModuleName } from './types'
import { submodule_permissions } from '../submodule_permissions'
import { createJobsPermissions } from '../submodule_permissions/jobs/jobs'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { createSchedulePermissions } from '../submodule_permissions/schedule/schedule'

const {
  project,
  accounting,
  // schedule,
  dispatch,
  dashboard,
  contacts,
  crm,
  forms,
  map,
  notes,
  properties,
  time_cards,
  fast_payment,
  instant_appointment,
  // jobs,
  quickbooks,
  todo,
  reviews,
  settings,
  memberships,
  messaging,
  call_tracking,
  estimates,

  // type_source,
} = submodule_permissions

export const module_permissions = () => {
  const jobDictionary = useAppSelector(selectJobDictionary)
  const job = createJobsPermissions(jobDictionary)
  const schedule = createSchedulePermissions(jobDictionary)
  return [
    {
      name: ModuleName.PROJECT,
      title: 'Projects',
      icon: <ProjectsIcon />,
      permission_key: Module.PROJECT,
      settings: project,
    },
    {
      name: ModuleName.ESTIMATES,
      title: 'Estimates',
      icon: <ProjectsIcon />,
      permission_key: Module.ESTIMATES,
      settings: estimates,
    },
    {
      name: ModuleName.SCHEDULE,
      title: 'Schedule',
      icon: <ScheduleIcon />,
      permission_key: Module.SCHEDULE,
      settings: schedule,
    },
    {
      name: ModuleName.DISPATCH,
      title: 'Dispatch',
      icon: <DispatchIcon />,
      permission_key: Module.DISPATCH,
      settings: dispatch,
    },
    {
      name: ModuleName.ACCOUNTING,
      title: 'Accounting',
      icon: <AccountingIcon />,
      permission_key: Module.ACCOUNTING,
      settings: accounting,
    },
    {
      name: ModuleName.PROPERTIES,
      title: 'Properties',
      icon: <PropertiesIcon />,
      permission_key: Module.PROPERTIES,
      settings: properties,
    },
    {
      name: ModuleName.CONTACTS,
      title: 'Contacts',
      icon: <ContactsIcon />,
      permission_key: Module.CONTACTS,
      settings: contacts,
    },
    {
      name: ModuleName.TIME_CARDS,
      title: 'Time Cards',
      icon: <TimecardsIcon />,
      permission_key: Module.TIME_CARDS,
      settings: time_cards,
    },
    {
      name: ModuleName.MAP,
      title: 'Map',
      icon: <MapIcon />,
      permission_key: Module.MAP,
      settings: map,
    },
    {
      name: ModuleName.DASHBOARD,
      title: 'Dashboard',
      icon: <DashboardIcon />,
      permission_key: Module.DASHBOARD,
      settings: dashboard,
    },
    {
      name: ModuleName.NOTES,
      title: 'Notes',
      icon: <NotesIcon width={20} height={20} />,
      permission_key: Module.NOTES,
      settings: notes,
    },
    {
      name: ModuleName.FAST_PAYMENT,
      title: 'Fast Payment',
      icon: <FastPaymentIcon />,
      permission_key: Module.FAST_PAYMENT,
      settings: fast_payment,
    },
    {
      name: ModuleName.INSTANT_APPOINTMENT,
      title: 'Instant Appointment',
      icon: <InstantAppointmentIcon />,
      permission_key: Module.INSTANT_APPOINTMENT,
      settings: instant_appointment,
    },
    {
      name: ModuleName.JOBS,
      title: jobDictionary.plural,
      icon: <JobsIcon />,
      permission_key: Module.JOBS,
      settings: job,
    },
    {
      name: ModuleName.QUICKBOOKS,
      title: 'Quickbooks',
      icon: <QuickbooksIcon />,
      permission_key: Module.QUICKBOOKS,
      settings: quickbooks,
    },
    {
      name: ModuleName.TODO,
      title: 'To do',
      icon: <TodoIcon />,
      permission_key: Module.TODO,
      settings: todo,
    },
    {
      name: ModuleName.REVIEWS,
      title: 'Reviews',
      icon: <ReviewsIcon />,
      permission_key: Module.REVIEWS,
      settings: reviews,
    },
    {
      name: ModuleName.FORMS,
      title: 'Forms',
      icon: <CustomFormIcon />,
      permission_key: Module.FORMS,
      settings: forms,
    },
    {
      name: ModuleName.MEMBERSHIPS,
      title: 'Memberships',
      icon: <MembershipIcon />,
      permission_key: Module.MEMBERSHIPS,
      settings: memberships,
    },
    {
      name: ModuleName.MESSAGING,
      title: 'Messaging',
      icon: <MessagingIcon />,
      permission_key: Module.MESSAGING,
      settings: messaging,
    },
    {
      name: ModuleName.CALL_TRACKING,
      title: 'Call Tracking',
      icon: <CallIcon width={20} height={20} fill='black' />,
      permission_key: Module.CALL_TRACKING,
      settings: call_tracking,
    },
    {
      name: ModuleName.CRM,
      title: 'CRM',
      icon: <CRMIcon />,
      permission_key: Module.CRM,
      settings: crm,
    },
    {
      name: ModuleName.SETTINGS,
      title: 'Settings',
      icon: <SettingsIcon />,
      permission_key: Module.SETTINGS,
      settings: settings,
    },
    // {
    //   name: ModuleName.TYPE_SOURCE,
    //   title: 'Type & Source',
    //   icon: <NotesIcon width={20} height={20} />,
    //   permission_key: Module.TYPE_SOURCE,
    //   settings: type_source,
    // },
  ]
}
