import { Result } from 'antd'
import { Button } from 'components/UIKit'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

export const TestDataModal = (/* { onClose } */) => {
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDisabled(false)
    }, 10000)

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <Modal
      width={500}
      title='Generating test data'
      open
      closable={false}
      footer={
        <Footer>
          <Button
            type='primary'
            onClick={() => window.location.reload()}
            disabled={disabled}
          >
            Got it
          </Button>
        </Footer>
      }
    >
      {disabled ? (
        <>
          <Text>
            <p>We are generating test data to help you explore the system.</p>
            <p>
              {' '}
              You can remove it anytime by clicking on your profile image (top
              right) and selecting ‘Delete test data’.
            </p>
          </Text>

          <Image>
            <img src={'/assets/process.gif'} alt='loading...' />
          </Image>
        </>
      ) : (
        <Result
          status='success'
          title='Your test data is ready!'
          subTitle='You can now explore the system with pre-generated data. If you ever want to remove it, click on your profile image (top right) and select ‘Delete test data’. Happy exploring!'
        />
      )}
    </Modal>
  )
}

const Image = styled.div`
  text-align: center;
`
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const Text = styled.div`
  margin-top: 20px;
  text-align: center;
`
