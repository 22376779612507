import { Button as AtndButton, ButtonProps } from 'antd'
import styled from 'styled-components'

interface Props extends ButtonProps {
  children: React.ReactNode
  secondary?: boolean
}

export const Button = ({ children, ...rest }: Props) => {
  return <StyledButton {...rest}>{children}</StyledButton>
}

const StyledButton = styled(AtndButton)<Props>`
  ${({ secondary }) =>
    secondary &&
    `
    background-color: #edeffe;
    color: #4285f4;
    border-color: #4285f4;

    // &:hover,
    &:focus {
      background-color: #edeffe;
      color: #4285f4;
      border-color: #4285f4;
    }
  `}
`
