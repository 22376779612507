import { Empty, Spin, Table, TableColumnsType } from 'antd'
import { fetchSupportTickets } from 'api/supportTickets'
import { Button } from 'components/UIKit'
import CreateTicketModal from 'containers/MainContent/Orcatec/Support/components/CreateTicketModal'
import TicketModal from 'containers/MainContent/Orcatec/Support/components/TicketModal'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import styled from 'styled-components'

interface SupportTicket {
  id: number
  number: string
  subject: string
  type: number
  created_at: number
  updated_at: number
  status: number
}

// Types of the ticket
const types = {
  1: 'Need help',
  2: 'Other',
  3: 'Technical issue',
}
export const statuses = {
  1: 'New',
  2: 'In progress',
  3: 'Resolved',
  4: 'Re-open',
}

export const SupportRequest = ({
  openRequestTicket,
}: {
  openRequestTicket: boolean
}) => {
  const authUser = useAppSelector(selectAuthUser)

  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState({
    sort: 'updated_at',
    order: 'desc',
    page: 1,
    perPage: 10,
    total: 10,
  })
  const [selectedTicketId, setSelectedTicketId] = useState<number | null>(null)
  const [newTicketModal, showNewTicketModal] = useState(openRequestTicket)

  async function getTicketsList() {
    setLoading(true)
    const { data, ...pagination } = await fetchSupportTickets(tableParams)
    setLoading(false)
    setTickets(data)
    setTableParams(pagination)
  }

  useEffect(() => {
    getTicketsList()
  }, [])

  const columns: TableColumnsType<SupportTicket> = [
    {
      title: 'ID',
      dataIndex: 'number',
      key: 'number',
      render: (id: string, record) => (
        <Link onClick={() => setSelectedTicketId(record.id)}>{id}</Link>
      ),
    },
    { title: 'Subject', dataIndex: 'subject', key: 'subject' },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: keyof typeof types) => types[type],
    },
    {
      title: 'Date sent',
      dataIndex: 'created_at',
      key: 'created_at',
      render: time => {
        return moment(time, 'X').format('DD/MM/YYYY, h:mma')
      },
    },
    {
      title: 'Updated date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: time => {
        return moment(time, 'X').format('DD/MM/YYYY, h:mma')
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: keyof typeof statuses) => statuses[status],
    },
  ]

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <Heading>
          <Title>Help requests </Title>

          <Button type='primary' onClick={() => showNewTicketModal(true)}>
            + Create new Help request
          </Button>
        </Heading>

        {tickets.length ? (
          <Table
            size='small'
            dataSource={tickets}
            columns={columns}
            loading={loading}
            pagination={
              tableParams?.total > 10
                ? {
                    current: tableParams.current_page,
                    total: tableParams.total,
                    pageSize: tableParams.per_page,
                    // showSizeChanger: true,
                  }
                : false
            }
          />
        ) : (
          <Empty description='No request tickets yet' />
        )}

        {selectedTicketId && (
          <TicketModal
            isShow
            onHide={() => setSelectedTicketId(null)}
            onClose={() => setSelectedTicketId(null)}
            me={authUser}
            ticketId={selectedTicketId}
          />
        )}

        {newTicketModal && (
          <CreateTicketModal
            isShow
            onHide={() => {
              showNewTicketModal(false)
              getTicketsList()
            }}
            me={authUser}
          />
        )}
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div``
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`
const Link = styled.p`
  color: #1890ff;
  cursor: pointer;
`
const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
`
