import React, { useState, useEffect, useRef, Fragment } from 'react'

import { Modal as BootstrapModal } from 'react-bootstrap'

import Preloader from '../../../components/Preloader'
import Modal from '../Modal'
import Header from './Header'
import MessageBag from './MessageBag'
import Reply from './Reply'
import ResolveConfirmation from './ResolveConfirmation'

import {
  ticket as fetchTicket,
  changeTicketStatus,
} from '../../../../../../api/supportTickets'
import {
  RESOLVED_STATUS,
  RE_OPEN_STATUS,
} from '../../../../../../helpers/supportTickets'

import './style.scss'

const TicketModal = ({ isShow, onHide, onClose, me, ticketId }) => {
  const [ticket, setTicket] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [tryResolve, setTryResolve] = useState(false)

  useEffect(() => {
    if (isShow) {
      fetch()
        .then(() => {
          scrollToBottom()
        })
        .catch(() => {
          handleHide()
        })
    }
  }, [isShow])

  const modalRef = useRef()

  const fetch = () => {
    return fetchTicket(ticketId).then(response => {
      setTicket(response.data)
    })
  }

  const scrollToBottom = () => {
    if (modalRef.current && modalRef.current._modal) {
      const { dialog } = modalRef.current._modal
      dialog.scrollTop = dialog.scrollHeight
    }
  }

  const handleHide = () => {
    onHide()
    setTicket(null)
  }

  const handleOnSentReply = message => {
    setIsLoading(true)
    fetch()
      .then(() => {
        scrollToBottom()
      })
      .catch(() => {
        const newMessages = ticket.messages || []
        newMessages.push(message)
        setTicket({ ...ticket, messages: newMessages })
      })
      .finally(() => setIsLoading(false))
  }

  const handleTryResolve = () => {
    setTryResolve(true)
  }

  const handleCloseResolveConf = () => {
    setTryResolve(false)
  }

  const handleConfirmResolve = () => {
    changeTicketStatus(ticket.id, RESOLVED_STATUS).then(() => {
      setTryResolve(false)
      handleHide()
    })
  }

  const handleReopen = () => {
    setIsLoading(true)

    changeTicketStatus(ticket.id, RE_OPEN_STATUS)
      .then(() => {
        fetch()
          .catch(() => {
            setTicket({ status: RE_OPEN_STATUS })
          })
          .finally(() => setIsLoading(false))
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const renderStartPreloader = !ticket && <Preloader />

  const renderPreloader = !!isLoading && (
    <div className='ticket__preloader'>
      <Preloader />
    </div>
  )

  const renderResolveConfirmation = !!tryResolve && (
    <ResolveConfirmation
      show={true}
      onHide={handleCloseResolveConf}
      onConfirm={handleConfirmResolve}
    />
  )

  const renderTicketBody = !!ticket && (
    <Fragment>
      <Header ticket={ticket} onReopen={handleReopen} />
      <MessageBag messages={ticket.messages || []} me={me} />
      {ticket.status !== RESOLVED_STATUS && (
        <Reply ticket={ticket} onSent={handleOnSentReply} />
      )}
    </Fragment>
  )

  return (
    <Modal
      ref={modalRef}
      isShow={isShow}
      titleComponent={() => (
        <div>
          <BootstrapModal.Title>{`Hey ${me.full_name}!`}</BootstrapModal.Title>
          <div>We respond to all requests within 24 hours maximum</div>
        </div>
      )}
      size='xl'
      onHide={handleHide}
    >
      {renderStartPreloader}
      {renderPreloader}
      {renderTicketBody}
      {renderResolveConfirmation}
      <div className='mt-5'>
        {/* <button className='btn btn-custom-info' onClick={handleHide}>
          Back
        </button> */}
        <div className='d-flex justify-content-end'>
          <button className='btn btn-custom-cancel' onClick={onClose}>
            Close
          </button>
          {!!ticket && ticket.status !== RESOLVED_STATUS && (
            <button
              className='btn btn-custom-info ml-2'
              onClick={handleTryResolve}
            >
              Mark as resolved
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default TicketModal
