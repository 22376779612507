import { Redirect, Route, Switch } from 'react-router-dom'
import { Signin } from './Signin/Signin'
// import { Signup } from './Signup/Signup'
import { ConfirmEmail } from './ConfirmEmail/ConfirmEmail'
import { ForgotPassword } from './ForgotPassword/ForgotPassword'
import { VerifyEmail } from './ConfirmEmail/VerifyEmail'
import styled from 'styled-components'
import { AuthMarketing } from './AuthMarketing/AuthMarketing'
import {
  IconCardv2,
  IconDoc,
  IconWallet,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { CreatePassword } from './CreatePassword/CreatePassword'
import { Signup } from './Signup/Signup'
// import { SignUpForm } from './Signup/SignUpForm'

export const Auth = () => {
  return (
    <Wrapper>
      <Switch>
        <Route path='/auth/signin'>
          <div>
            <StyledImg src='/assets/Orcatec_logo.svg' alt='Orcatec Logo' />
            <AuthWrapper>
              <Signin />
              <AuthMarketing />
            </AuthWrapper>
          </div>
        </Route>
        <Route path='/auth/signup'>
          <div>
            <StyledImg src='/assets/Orcatec_logo.svg' alt='Orcatec Logo' />
            <AuthWrapper>
              <Signup />
              {/* <SignUpForm /> */}
              <AuthMarketing />
            </AuthWrapper>

            <Tiles>
              <Tile>
                <IconWallet />
                No setup fees
              </Tile>
              <Tile>
                <IconCardv2 />
                No credit card needed
              </Tile>
              <Tile>
                <IconDoc />
                No contract needed
              </Tile>
            </Tiles>
          </div>
        </Route>
        <Route path='/auth/confirm-email'>
          <ConfirmEmail />
        </Route>
        <Route path='/auth/verify-email'>
          <VerifyEmail />
        </Route>
        <Route path='/auth/forgot-password'>
          <div>
            <StyledImg src='/assets/Orcatec_logo.svg' alt='Orcatec Logo' />
            <ForgotPassword />
          </div>
        </Route>
        <Route path='/auth/create-password'>
          <div>
            <StyledImg src='/assets/Orcatec_logo.svg' alt='Orcatec Logo' />
            <CreatePassword />
          </div>
        </Route>

        <Redirect to='/auth/signin' />
      </Switch>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const AuthWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
`

const StyledImg = styled.img`
  height: 40px;
  margin-left: 120px;
  margin-bottom: 50px;

  @media (max-width: 450px) {
    margin-left: 80px;
  }
`

const Tiles = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  color: #fff;
  margin-top: 24px;
  max-width: 450px;

  @media (min-width: 1000px) {
    display: none;
  }
`
const Tile = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 12px;
  border-radius: 6px;
  background-color: #ffffff40;

  span {
    font-size: 28px;
  }
`
