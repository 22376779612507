import React, { useRef, useState, useEffect, forwardRef, Ref } from 'react'
import ReactCropper, { ReactCropperElement } from 'react-cropper'
import {
  message,
  Popconfirm,
  Upload,
  UploadProps,
  Modal,
  Slider,
  Tooltip,
  Button,
  Spin,
} from 'antd'

import styled from 'styled-components'
import {
  CloseCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  UploadOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons'
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/lib/upload/interface'
import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
import { Media } from 'types/Media'

const STEP = 10
const MIN_VALUE = -180
const MAX_VALUE = 180
const ZOOM = 0.1
const ZOOM_STEP = 0.1
const AVATAR_SIZE = 80

const ACCEPT_FILE = ['image/jpeg', 'image/png']

interface IProps {
  customIcon?: React.ReactNode
  image: Media['path']
  minimalistic?: boolean
  onUploadFinish: (file: File) => Promise<any>
  onDeleteImage: () => Promise<any>
}

export const ImageUploader = forwardRef(
  (
    { image, minimalistic, onUploadFinish, onDeleteImage, customIcon }: IProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const cropperRef = useRef<ReactCropperElement>(null)
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState<string>('')
    const [cropImage, setCropImage] = useState('')
    const [cropFile, setCropFile] = useState<RcFile>('')
    const [openCropModal, setOpenCropModal] = useState(false)
    const [rotate, setRotate] = useState(0)
    const [zoom, setZoom] = useState(ZOOM)

    useEffect(() => {
      if (image) setImageUrl(image)
    }, [image])

    const handleChange: UploadProps['onChange'] = (
      info: UploadChangeParam<UploadFile>,
    ) => {
      if (ACCEPT_FILE.includes(info.file.type)) {
        getBase64(info.file.originFileObj as RcFile, url => {
          setCropImage(url)
          setCropFile(info.file)
          setOpenCropModal(true)
        })
      }
    }

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )

    const handleUploadFile = async (file: File): Promise<void> => {
      try {
        setLoading(true)
        await onUploadFinish(file)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    const onCrop = async (): Promise<void> => {
      setLoading(true)
      try {
        const cropper = cropperRef.current?.cropper

        if (cropper) {
          cropper.getCroppedCanvas().toBlob((blob: Blob | null) => {
            if (blob) {
              const file = new File([blob], cropFile.name)
              handleUploadFile(file)
            }
          }, 'image/png') // if use file.type or image/jpeg - > background color is dark after crop
        }
        handleCloseCropModal()
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    const rotateLeft = () => {
      if (rotate - 90 < MIN_VALUE) return
      const cropper = cropperRef.current?.cropper
      const rotateTo = rotate - 90
      cropper?.rotateTo(rotateTo)
      setRotate(rotateTo)
    }
    const rotateRight = () => {
      if (rotate + 90 > MAX_VALUE) return
      const cropper = cropperRef.current?.cropper
      const rotateTo = rotate + 90
      cropper?.rotateTo(rotateTo)
      setRotate(rotateTo)
    }

    const handleRotate = (value: number) => {
      const cropper = cropperRef.current?.cropper
      cropper?.rotateTo(value)
      setRotate(value)
    }

    const handleZoomIn = () => {
      const cropper = cropperRef.current?.cropper
      const zoomTo = zoom + ZOOM_STEP

      if (zoomTo > 2) return
      cropper?.zoomTo(zoomTo)
      setZoom(zoomTo)
    }
    const handleZoomOut = () => {
      const cropper = cropperRef.current?.cropper
      const zoomTo = zoom - ZOOM_STEP

      if (zoomTo < 0) return
      cropper?.zoomTo(zoomTo)
      setZoom(zoomTo)
    }
    const handleZoom = (value: number) => {
      const cropper = cropperRef.current?.cropper

      if (value > 2 || value < 0) return

      cropper?.zoomTo(value)
      setZoom(value)
    }

    const handleCloseCropModal = () => {
      setOpenCropModal(false)
      setCropImage('')
      setCropFile('')
      setRotate(0)
      setZoom(ZOOM)
    }
    return (
      <Wrapper>
        <StyledUpload
          listType={minimalistic ? 'text' : 'picture-card'}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          customRequest={() => null}
          ref={ref}
          openFileDialogOnClick
        >
          {imageUrl ? (
            <Spin spinning={loading}>
              <ImageWrapper>
                <Popconfirm
                  title='Delete this image?'
                  onConfirm={e => {
                    e?.stopPropagation()
                    setImageUrl('')
                    onDeleteImage()
                  }}
                  onCancel={e => e?.stopPropagation()}
                >
                  <CloseCircleOutlined onClick={e => e.stopPropagation()} />
                </Popconfirm>
                <Avatar
                  className='company_image'
                  pictureURL={imageUrl}
                  size={AVATAR_SIZE}
                />
              </ImageWrapper>
            </Spin>
          ) : (
            <>
              {minimalistic ? (
                <Button
                  icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
                >
                  {loading ? 'Uploading...' : 'Upload file'}
                </Button>
              ) : (
                customIcon || uploadButton
              )}
            </>
          )}
        </StyledUpload>
        <Modal
          title='Edit Image'
          onOk={onCrop}
          onCancel={handleCloseCropModal}
          visible={openCropModal}
          destroyOnClose
          centered
          maskClosable={false}
        >
          <ReactCropper
            src={cropImage}
            style={{ height: 400, width: '100%' }}
            ref={cropperRef}
            background={true}
            responsive={true}
            checkOrientation={true}
          />
          <ControlsWrapper>
            <ControlsRow>
              <ControlIcon onClick={rotateLeft}>
                <Tooltip title='Rotate Left'>
                  <RotateLeftOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
              </ControlIcon>
              <Slider
                onChange={handleRotate}
                onAfterChange={handleRotate}
                step={STEP}
                style={{ width: '200px' }}
                value={rotate}
                max={MAX_VALUE}
                min={MIN_VALUE}
              />
              <ControlIcon onClick={rotateRight}>
                <Tooltip title='Rotate Right'>
                  <RotateRightOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
              </ControlIcon>
            </ControlsRow>
            <ControlsRow>
              <ControlIcon onClick={handleZoomOut}>
                <Tooltip title='Zoom Out'>
                  <ZoomOutOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
              </ControlIcon>
              <Slider
                onChange={handleZoom}
                onAfterChange={handleZoom}
                step={ZOOM_STEP}
                style={{ width: '200px' }}
                value={zoom}
                min={0}
                max={2}
              />
              <ControlIcon onClick={handleZoomIn}>
                <Tooltip title='Zoom In'>
                  <ZoomInOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
              </ControlIcon>
            </ControlsRow>
          </ControlsWrapper>
        </Modal>
      </Wrapper>
    )
  },
)

ImageUploader.displayName = 'ImageUploader'

const Wrapper = styled.div``

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  if (!img) return
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))

  reader.readAsDataURL(img)
}

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = ACCEPT_FILE.includes(file.type)
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 20
  if (!isLt2M) {
    message.error('Image must be smaller than 20MB!')
  }
  return isJpgOrPng && isLt2M
}

const ImageWrapper = styled.div`
  position: relative;
  & .company_image {
    border-radius: 0;
    background-size: contain;
    background-position: center;
  }

  .anticon {
    color: #fff;
    position: absolute;
    right: 0;
    padding: 4px;
  }

  &:hover {
    .anticon {
      color: red;
    }
  }
`
const ControlsWrapper = styled.div``
const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
`
const ControlIcon = styled.div`
  cursor: pointer;
`

const StyledUpload = styled(Upload)`
  .ant-upload {
    width: 80px;
    height: 80px;
    border-color: #c4d9ed;
    border-radius: 6px;
    margin-bottom: 0;
  }
`
